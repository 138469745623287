import React from 'react'
import './AboutUs.css'
import Header from '../Header/Header'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import SpecialityOne from '../../assets/Icons/speciality-1.svg'
import SpecialityTwo from '../../assets/Icons/speciality-2.svg'
import CollectionImg1 from "../../assets/who_we_are/img_1.svg"
import CollectionImg2 from "../../assets/who_we_are/img_2.svg"
import CollectionImg3 from "../../assets/who_we_are/img_3.svg"
import CollectionImg4 from "../../assets/who_we_are/img_4.svg"
import asideImg1 from '../../assets/SearchResults/aside-img-1.png';
import asideImg2 from '../../assets/SearchResults/aside-img-2.png';

const AboutUs = () => {
  return (
    <div className='about-us-wrapper'>
      <Header />
      <section className="who-we-are-section">
        <Container>
          <Row className='mx-0 justify-content-between'>
            <Col md={8} className='left-col-abt-us'>
              <div className="heading">
                <h3 className="section-heading">WHO ARE WE</h3>
              </div>
              <div className="who-we-are-content">
                <div className="flex-grow-1">
                  <div className="d-flex flex-column">
                    <h3 className="section-title desktop-title">
                      Making your home search<br /> easy and fun
                    </h3>
                    <h3 className="section-title mobile-title d-none">
                      We make your home search easy and fun
                    </h3>
                    <p className="section-description">
                      At Weliving, we believe in challenging the status quo in real estate. Our mission is to redefine your home search experience, making it easier, more enjoyable, and more efficient. We stand out from the competition by offering modern and comfortable living spaces that cater to your needs and lifestyle. Whether you're looking for a PG/Coliving space, house tenancy, or guest house, we have something for everyone.<br></br>
                      
                    </p>
                    <p className="section-description">
  At WeLiving, we believe in creating memorable experiences. Our platform connects travelers with local hosts, providing authentic stays that feel like home. With a commitment to quality and community, we empower hosts to share their spaces and stories. Join us in redefining travel—experience a destination like a local.
</p>

{/* <p className="section-description-mb">
At WeLiving, we are dedicated to transforming the home rental experience. Our mission is to simplify your search for houses, offering a diverse range of modern, comfortable living spaces tailored to your lifestyle. Whether you seek a cozy apartment, a spacious family home, or a guest house, WeLiving connects you with properties that feel like home.
</p> */}

                  </div>
                </div>
                <Row className="d-flex">
                  <Col md={7} className="specialities d-flex flex-column">
                    <div>
                      <Image src={SpecialityOne} alt="icon" />
                      <h3>Redefine your home search </h3>
                    </div>
                    <div>
                      <Image src={SpecialityTwo} alt="icon" />
                      <h3>Comfort, Convenience &<br /> Luxury</h3>
                    </div>
                    <div>
                      <Image src={SpecialityTwo} alt="icon" />
                      <h3>Wide range of housing<br /> options</h3>
                    </div>
                  </Col>

                  <Col md={5} className="d-flex align-items-center image-gallery">
                    <div className="d-flex flex-column">
                      <Image className="mb-3" alt="img" src={CollectionImg1} />
                      <Image className="mb-3" alt="img" src={CollectionImg2} />
                    </div>
                    <div className="d-flex flex-column ms-3">
                      <Image className="mb-3" alt="img" src={CollectionImg3} />
                      <Image className="mb-3" alt="img" src={CollectionImg4} />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="heading second-section">
                <h3 className="section-heading">WHAT WE PROVIDE</h3>
              </div>
              <div className="who-we-are-content">
                <p className="section-description mb-4">
                  We prioritize convenience in everything we do. From our user-friendly website to our attentive customer service, we ensure your home search is smooth and hassle-free.
                </p>
                <p className="section-description">
                  Making Your Home Search Easy and Fun: Our platform is designed to simplify your search for the perfect home. Browse through a variety of listings, each carefully curated to meet your preferences. With detailed descriptions, photos, and virtual tours, you'll find the perfect home in no time.
                  Find a Perfect Home You Love!: Discover properties that match your lifestyle, budget, and location preferences. Our dedicated team is here to assist you every step of the way, ensuring a seamless transition into your new home.
                </p>
              </div>
            </Col>
            <Col className='aside-column pe-0'>
              <div className='aside-img'>
                <Image src={asideImg1} alt='aside image' />
                <div className="d-flex justify-content-center">
                  <Button className='black-btn-shadow'>explore</Button>
                </div>
              </div>
              <div className='aside-img'>
                <Image src={asideImg2} alt='aside image' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default AboutUs