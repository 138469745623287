import React from 'react'
import './NewAdminHeader.css'
import { Button, Form, FormControl, Image, NavDropdown } from 'react-bootstrap'
import { Bell, Search } from 'lucide-react'
import SettingsIcon from '../../../assets/AdminHeader/settings.svg';
import NotificationsIcon from '../../../assets/AdminHeader/notifications.svg';
import Avatar from '../../../assets/AdminHeader/avatar.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userData } from '../../Store/Shared';
import callAPI from '../../../commonservices/APIRequests';

const NewAdminHeader = () => {

  const { userData } = useSelector(store => store.sharedReducer);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const SignOut = () => {
    callAPI.post('/users/logout', { userId: userData?._id ?? '' })
      .then(res => {
        if (res.status === 200) {
          localStorage.clear()
          dispatch(userData({}))
          navigate('/')
        }
      })
  }
  return (
    <div className='admin-header-wrapper'>
      <div className='header-box position-relative d-flex align-items-center'>
        <Form inline className="search-box-articles">
          <FormControl type="text" placeholder="Search for something" className="mr-sm-2" />
          <Button variant="outline-dark"><Search /></Button>
        </Form>
        <div>
          <Image src={SettingsIcon} alt="settings" />
        </div>
        <div>
          <Image src={NotificationsIcon} alt="notifications" />
        </div>
        <NavDropdown className='d-flex align-items-center' title={<span className="d-flex align-items-center text-white"><Image src={Avatar} alt="user_img" /></span>} id="basic-nav-dropdown">
          <NavDropdown.Item onClick={() => SignOut()}>Sign Out</NavDropdown.Item>
        </NavDropdown>
      </div>
    </div>
  )
}

export default NewAdminHeader