import React, { useState, useEffect } from "react"
import { Form, Button, Tab, Row, Col, Nav, Image } from 'react-bootstrap'
import callAPI from "../../commonservices/APIRequests"
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom'
import './NewForm.css'
import { toast } from 'react-toastify';
import { fileToBase64 } from "../../commonservices/commonMethods";
import SideNavBar from "../NewAdminPortal/SideNavBar/SideNavBar";
import NewAdminHeader from "../NewAdminPortal/NewAdminHeader/NewAdminHeader";
import FileIcon from "../../assets/Icons/add-files.png"

const NewForm = (props) => {
    const [foramData, setForamData] = useState({})
    const [validated, setValidated] = useState(false)
    const [isPropertyType, setIsPropertyType] = useState(false)
    const [isAmenities, setIsAmenities] = useState(false)
    const [locations, setLocations] = useState([])
    const [isLocation, setIsLocation] = useState(false)

    const LifespacesOptions = [
        { label: 'Private Room', value: 'Private Room' },
        { label: 'Double Sharing', value: 'Double Sharing' },
        { label: 'Triple Sharing', value: 'Triple Sharing' }
    ]

    const livingOptions = [
        { label: 'Bungalows', value: 'Bungalows' },
        { label: 'Villas', value: 'Villas' },
        { label: 'Apartments', value: 'Apartments' }
    ]

    const WecationOption = [
        { label: 'Bungalows', value: 'Bungalows' },
        { label: 'Chalets', value: 'Chalets' },
        { label: 'Skivillas', value: 'Skivillas' },
        { label: 'Penthouses', value: 'Penthouses' },
        { label: 'Studio Apartments', value: 'Studio Apartments' }
    ]

    const availabilityOptions = [
        { label: 'Vacant', value: 'Vacant' },
        { label: 'Occupied', value: 'Occupied' },
    ]

    const amenities = [
        { label: 'CCTV', value: 'CCTV' },
        { label: 'Wifi', value: 'Wifi' },
        { label: 'Cleaning', value: 'Cleaning' },
        { label: 'Laundary', value: 'Laundary' },
        { label: 'Biometric', value: 'Biometric' },
        { label: 'Security', value: 'Security' },
        { label: 'Gym', value: 'Gym' },
    ]

    const tagOptions = [
        { label: 'Popular', value: 'Popular' },
        { label: 'Discounted', value: 'Discounted' },
        { label: 'New-listings', value: 'New-listings' }
    ]

    const collectionOptions = [
        { label: 'Business Stays', value: 'Business Stays' },
        { label: 'Family', value: 'Family' },
        { label: 'Friends', value: 'Friends' },
        { label: 'Couples', value: 'Couples' }
    ]

    const location = useLocation()

    const feilds = {
        Weliving:
            <Row className="new-form-fields">
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='propertyName'
                        value={foramData?.propertyName ?? ''}
                        placeholder="Enter Property Name"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Property Name
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        required
                        name='propertyDescription'
                        value={foramData?.propertyDescription ?? ''}
                        placeholder="Enter Description"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid description
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Types</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={livingOptions ? livingOptions : []}
                        value={livingOptions?.filter(item => item.value === foramData?.accommodationType)[0] ?? null}
                        onChange={value => handleChange('accommodationType', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Property Type.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={5} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Location</Form.Label>
                    <Select
                        isClearable={true}
                        isSearchable={true}
                        name='Select Location'
                        placeholder='Select...'
                        className="react-select-dealerlist"
                        options={locations || []}
                        value={foramData?.propertyLocation && Array.isArray(locations)
                            ? locations.filter(item => item.value === foramData?.propertyLocation)[0] : null}
                        onChange={val => handleChange('propertyLocation', val?.value)}
                        menuPlacement='auto' />
                    {isLocation ? <p className="custom-error-text">Please select a Location. </p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Latitude</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='latitude'
                        value={foramData?.latitude ?? ''}
                        placeholder="Enter latitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid latitude
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Longitude</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='longitude'
                        value={foramData?.longitude ?? ''}
                        placeholder="Enter longitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid longitude
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Google Maps Link</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='propertyLocationInMaps'
                        value={foramData?.propertyLocationInMaps ?? ''}
                        placeholder="Enter longitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid property location in maps
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Full Address</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='fullAddress'
                        value={foramData?.fullAddress ?? ''}
                        placeholder="Enter Location"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid full Address
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12}>
                    <Form.Label className="mb-0">Amenities</Form.Label>
                    <Select
                        isMulti
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={amenities ? amenities : []}
                        value={amenities?.filter(item => foramData?.amenities?.includes(item.value))}
                        onChange={value => handleChange('amenities', value.map(item => item.value))}
                        menuPlacement='auto' />
                    {isAmenities ? <p className="custom-error-text">Please Select valid Accommodation Type.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Id</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='property_Id'
                        value={foramData?.property_Id ?? ''}
                        placeholder="Enter Property Id"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid property Id
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">BHK Type</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='bhkType'
                        value={foramData?.bhkType ?? ''}
                        placeholder="Enter BHk Type"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid BHk Type
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Monthly Rent</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='monthlyRentForRoom'
                        value={foramData?.monthlyRentForRoom ?? ''}
                        placeholder="Enter Monthly Rent"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Monthly Rent
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Security  Deposity</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='securityFee'
                        value={foramData?.securityFee ?? ''}
                        placeholder="Enter Security Deposity"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Security Deposity
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                    <Form.Label className="mb-0">Bath Rooms</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='noOfBathRooms'
                        value={foramData?.noOfBathRooms ?? ''}
                        placeholder="Enter bathRooms"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Bath Rooms
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                    <Form.Label className="mb-0">Balcony</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='balcony'
                        value={foramData?.balcony ?? ''}
                        placeholder="Enter Balcony"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Balcony
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                    <Form.Label className="mb-0">Floor</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='noOfFloors'
                        value={foramData?.noOfFloors ?? ''}
                        placeholder="Enter Floor"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Floor
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Maintanance Fee</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='maintananceFee'
                        value={foramData?.maintananceFee ?? ''}
                        placeholder="Enter Maintanance Fee"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Maintanance Fee
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="label-color single-line-label mb-0">Available From</Form.Label>
                    <Form.Control
                        type="date"
                        name="availableFrom"
                        className='content-sm-text height-42'
                        placeholder="Select Date"
                        value={foramData?.availableFrom ?? ''}
                        max="2999-12-31"
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Tags</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={tagOptions ? tagOptions : []}
                        value={tagOptions?.filter(item => item.value === foramData?.tags)[0] ?? null}
                        onChange={value => handleChange('tags', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Tag.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Collection Type</Form.Label>
                    <Select
                        isSearchable={true}
                        isMulti={true}  // Enable multi-select
                        className="react-select-dealerlist"
                        options={collectionOptions ? collectionOptions : []}
                        value={collectionOptions?.filter(item => foramData?.collectionType?.includes(item.value)) ?? []}
                        onChange={values => handleChange('collectionType', values.map(value => value.value))}
                        menuPlacement='auto'
                    />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid collection type.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Room Availability</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={availabilityOptions ? availabilityOptions : []}
                        value={availabilityOptions?.filter(item => item.value === foramData?.roomAvailability)[0] ?? null}
                        onChange={value => handleChange('roomAvailability', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Room Availability type.</p> : null}
                </Form.Group>
            </Row>,
        Wecation:
            <Row className="new-form-fields">
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='propertyName'
                        value={foramData?.propertyName ?? ''}
                        placeholder="Enter Property Name"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Property Name
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        required
                        name='propertyDescription'
                        value={foramData?.propertyDescription ?? ''}
                        placeholder="Enter Description"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid description
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Types</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={WecationOption ? WecationOption : []}
                        value={WecationOption?.filter(item => item.value === foramData?.accommodationType)[0] ?? null}
                        onChange={value => handleChange('accommodationType', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Property Type.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Location</Form.Label>
                    <Select
                        isClearable={true}
                        isSearchable={true}
                        name='Select Location'
                        placeholder='Select...'
                        className="react-select-dealerlist"
                        options={locations || []}
                        value={foramData?.propertyLocation && Array.isArray(locations)
                            ? locations.filter(item => item.value === foramData?.propertyLocation)[0] : null}
                        onChange={val => handleChange('propertyLocation', val?.value)}
                        menuPlacement='auto' />
                    {isLocation ? <p className="custom-error-text">Please select a Location. </p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Latitude</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='latitude'
                        value={foramData?.latitude ?? ''}
                        placeholder="Enter latitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid latitude
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Longitude</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='longitude'
                        value={foramData?.longitude ?? ''}
                        placeholder="Enter longitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid longitude
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Google Maps Link</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='propertyLocationInMaps'
                        value={foramData?.propertyLocationInMaps ?? ''}
                        placeholder="Enter longitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid property location in maps
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Full Address</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='fullAddress'
                        value={foramData?.fullAddress ?? ''}
                        placeholder="Enter Location"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid full Address
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12}>
                    <Form.Label className="mb-0">Amenities</Form.Label>
                    <Select
                        isMulti
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={amenities ? amenities : []}
                        value={amenities?.filter(item => foramData?.amenities?.includes(item.value))}
                        onChange={value => handleChange('amenities', value.map(item => item.value))}
                        menuPlacement='auto' />
                    {isAmenities ? <p className="custom-error-text">Please Select valid Amenities.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Id</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='property_Id'
                        value={foramData?.property_Id ?? ''}
                        placeholder="Enter Property Id"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid property Id
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">BHK Type</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='bhkType'
                        value={foramData?.bhkType ?? ''}
                        placeholder="Enter BHk Type"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid BHk Type
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={3}>
                    <Form.Label className="mb-0">Bath Rooms</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='noOfBathRooms'
                        value={foramData?.noOfBathRooms ?? ''}
                        placeholder="Enter bathRooms"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Bath Rooms
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={3}>
                    <Form.Label className="mb-0">Floor</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='noOfFloors'
                        value={foramData?.noOfFloors ?? ''}
                        placeholder="Enter Floor"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Floor
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={3}>
                    <Form.Label className="mb-0">Bed Rooms</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='noOfBedRooms'
                        value={foramData?.noOfBedRooms ?? ''}
                        placeholder="Enter Bed Rooms"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Bed Rooms
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={3}>
                    <Form.Label className="mb-0">Kitchen</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='noOfkitchens'
                        value={foramData?.noOfkitchens ?? ''}
                        placeholder="Enter Kitchen"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Kitchen
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Regular Price</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='price'
                        value={foramData?.price ?? ''}
                        placeholder="Enter Regular Price"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Regular Price
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Sale Price</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='salePrice'
                        value={foramData?.salePrice ?? ''}
                        placeholder="Enter Sale Price"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Sale Price
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Security Deposit</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='securityFee'
                        value={foramData?.securityFee ?? ''}
                        placeholder="Enter Security Deposit"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Security Deposit
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Tags</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={tagOptions ? tagOptions : []}
                        value={tagOptions?.filter(item => item.value === foramData?.tags)[0] ?? null}
                        onChange={value => handleChange('tags', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Tag.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Collection Type</Form.Label>
                    <Select
                        isSearchable={true}
                        isMulti={true}  // Enable multi-select
                        className="react-select-dealerlist"
                        options={collectionOptions ? collectionOptions : []}
                        value={collectionOptions?.filter(item => foramData?.collectionType?.includes(item.value)) ?? []}
                        onChange={values => handleChange('collectionType', values.map(value => value.value))}
                        menuPlacement='auto'
                    />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid collection type.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Room Availability</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={availabilityOptions ? availabilityOptions : []}
                        value={availabilityOptions?.filter(item => item.value === foramData?.roomAvailability)[0] ?? null}
                        onChange={value => handleChange('roomAvailability', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Room Availability type.</p> : null}
                </Form.Group>
            </Row>,
        Welifespaces:
            <Row className="new-form-fields">
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='propertyName'
                        value={foramData?.propertyName ?? ''}
                        placeholder="Enter Property Name"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Property Name
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        required
                        name='propertyDescription'
                        value={foramData?.propertyDescription ?? ''}
                        placeholder="Enter Description"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid description
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Room Types</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={LifespacesOptions ? LifespacesOptions : []}
                        value={LifespacesOptions?.filter(item => item.value === foramData?.accommodationType)[0] ?? null}
                        onChange={value => handleChange('accommodationType', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid RoomType.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Property Id</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='property_Id'
                        value={foramData?.property_Id ?? ''}
                        placeholder="Enter Property Id"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid property Id
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Location</Form.Label>
                    <Select
                        isClearable={true}
                        isSearchable={true}
                        name='Select Location'
                        placeholder='Select...'
                        className="react-select-dealerlist"
                        options={locations || []}
                        value={foramData?.propertyLocation && Array.isArray(locations)
                            ? locations.filter(item => item.value === foramData?.propertyLocation)[0] : null}
                        onChange={val => handleChange('propertyLocation', val?.value)}
                        menuPlacement='auto' />
                    {isLocation ? <p className="custom-error-text">Please select a Location. </p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Latitude</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='latitude'
                        value={foramData?.latitude ?? ''}
                        placeholder="Enter latitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid latitude
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Longitude</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='longitude'
                        value={foramData?.longitude ?? ''}
                        placeholder="Enter longitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid longitude
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Google Maps Link</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name='propertyLocationInMaps'
                        value={foramData?.propertyLocationInMaps ?? ''}
                        placeholder="Enter longitude"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid property location in maps
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Shared Room</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='sharedRoomPrice'
                        value={foramData?.sharedRoomPrice ?? ''}
                        placeholder="Enter Shared Room"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Shared Rooms
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Triple Sharing Room</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='tripleSharingPrice'
                        value={foramData?.tripleSharingPrice ?? ''}
                        placeholder="Enter Triple Sharing Room"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Triple Sharing Room
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Private Room</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='privateRoomPrice'
                        value={foramData?.privateRoomPrice ?? ''}
                        placeholder="Enter Private Room"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid PrivateRoom
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Monthly Rent</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='monthlyRentForRoom'
                        value={foramData?.monthlyRentForRoom ?? ''}
                        placeholder="Enter Monthly Rent"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Monthly Rent
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Monthly Rent For Bed</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='monthlyRentForBed'
                        value={foramData?.monthlyRentForBed ?? ''}
                        placeholder="Enter Monthly Rent"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Monthly Rent For Bed
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Maintanance Fee</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='maintananceFee'
                        value={foramData?.maintananceFee ?? ''}
                        placeholder="Enter Maintanance Fee"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Maintanance Fee
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Security Deposit</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='securityFee'
                        value={foramData?.securityFee ?? ''}
                        placeholder="Enter Security Deposit"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Security Deposit
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12}>
                    <Form.Label className="mb-0">Amenities</Form.Label>
                    <Select
                        isMulti
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={amenities ? amenities : []}
                        value={amenities?.filter(item => foramData?.amenities?.includes(item.value))}
                        onChange={value => handleChange('amenities', value.map(item => item.value))}
                        menuPlacement='auto' />
                    {isAmenities ? <p className="custom-error-text">Please Select valid Amenities.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="label-color single-line-label mb-0">Available From</Form.Label>
                    <Form.Control
                        type="date"
                        name="availableFrom"
                        className='content-sm-text height-42'
                        placeholder="Select Date"
                        value={foramData?.availableFrom ?? ''}
                        max="2999-12-31"
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Tags</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={tagOptions ? tagOptions : []}
                        value={tagOptions?.filter(item => item.value === foramData?.tags)[0] ?? null}
                        onChange={value => handleChange('tags', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Tag.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Collection Type</Form.Label>
                    <Select
                        isSearchable={true}
                        isMulti={true}  // Enable multi-select
                        className="react-select-dealerlist"
                        options={collectionOptions ? collectionOptions : []}
                        value={collectionOptions?.filter(item => foramData?.collectionType?.includes(item.value)) ?? []}
                        onChange={values => handleChange('collectionType', values.map(value => value.value))}
                        menuPlacement='auto'
                    />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid collection type.</p> : null}
                </Form.Group>
                <Form.Group as={Col} md={6}>
                    <Form.Label className="mb-0">Number of Beds Available</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name='noOfBedsAvailable'
                        value={foramData?.noOfBedsAvailable ?? ''}
                        placeholder="Enter Number of Beds Available"
                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid Number of Beds Available
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={12} controlId="formBasicEmail">
                    <Form.Label className="mb-0">Room Availability</Form.Label>
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={availabilityOptions ? availabilityOptions : []}
                        value={availabilityOptions?.filter(item => item.value === foramData?.roomAvailability)[0] ?? null}
                        onChange={value => handleChange('roomAvailability', value?.value)}
                        menuPlacement='auto' />
                    {isPropertyType ? <p className="custom-error-text">Please Select valid Room Availability type.</p> : null}
                </Form.Group>
            </Row >
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (location.state.isEdit) {
            setForamData(location.state.formData)
        }
    }, [location.state.isEdit])

    useEffect(() => {
        getLocations()
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true)

        if (!foramData?.propertyLocation) {
            setIsLocation(true)
            return false
        }

        if (!foramData?.amenities) {
            setIsAmenities(true)
        }
        if (!foramData?.accommodationType) {
            setIsPropertyType(true)
        }
        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            foramData.category = (location.state?.type === 'Welifespaces') ? 'Lifespaces' : (location.state?.type === 'Weliving') ? 'Housing' : (location.state?.type === 'Wecation') ? 'We-Cation' : location.state?.type
            foramData.propertyId = location.state?.formData?._id ?? ''

            callAPI.post('/property/saveAndUpdateProperty', foramData)
                .then(res => {
                    if (res.status === 200) {
                        toast.success(`Property ${location.state?.isEdit ? 'Updated' : 'Created'} Successfully`);
                        navigate('/newAdminPortal')
                        setForamData({})
                        setValidated(false)
                        setIsAmenities(false)
                        setIsPropertyType(false)
                        setIsLocation(false)
                    } else {
                        setIsLocation(false)
                        toast.error(res.message);
                        setValidated(false)
                        setForamData({})
                        setIsAmenities(false)
                        setIsPropertyType(false)
                    }
                })
        }
    }

    const handleChange = (key, value) => {
        setForamData({ ...foramData, [key]: value })
    }

    const uploadFile = async (key, event) => {
        const file = event.target.files
        let uploadData = []
        for (let i = 0; i < file.length; i++) {
            let document = {
                fileName: file[i].name,
                base64Image: await fileToBase64(file[i]),
                fileType: file[i].type
            }
            uploadData.push(document)
        }
        setForamData({ ...foramData, ['uploadData']: uploadData })

    }

    const getLocations = () => {
        callAPI.get('/property/getLocations')
            .then(res => {
                if (res.status === 200) {
                    const data = res.data?.data?.map(item => {
                        return {
                            label: item.LocationName,
                            value: item.LocationName
                        }
                    })
                    setLocations(data)
                } else {
                    setLocations([])
                }
            })
    }

    return (
        <>
            <div className='admin-dashboard-wrapper'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className='mx-0 admin-contdent-row'>
                        <SideNavBar />
                        <Col className='side-nav-content'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='admin-content-wrapper'>
                                        <NewAdminHeader />
                                        <div className='admin-tab-content-box'>
                                            <div className='admin-breadcrumb-header d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <h2>Property Details</h2>
                                                    <p>Home /<span className="cursor-pointer" onClick={() => navigate('/newAdminPortal')}>All Properties</span> / {location.state?.type}</p>
                                                </div>
                                            </div>
                                            <div className="newform-wrapper">
                                                <Form
                                                    noValidate
                                                    validated={validated}
                                                    onSubmit={(event) => handleSubmit(event)}
                                                >
                                                    <Row>
                                                        <Col md={6}>
                                                            {feilds[location.state?.type]}
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="file-upload-wrapper">
                                                                <Image className='file-icon' src={FileIcon} alt="file-icons" />
                                                                <input type='file' accept=".png, .jpg" onChange={(e) => uploadFile('fileUpload', e)} multiple />
                                                                <p>Drop your images here, or browse</p>
                                                                <p>Jpeg, png are allowed</p>
                                                            </div>
                                                            {
                                                                foramData?.uploadData?.length > 0 ? foramData?.uploadData?.map(item => {
                                                                    return <p>{item?.fileName}</p>
                                                                })

                                                                    : null
                                                            }


                                                            {/* <div className="d-flex">
                                                                {location.state?.formData?.images.map((image) => <div>
                                                                    <Image className='file-icon' src={`${image}`} alt="file-icons" />
                                                                </div>)}
                                                            </div> */}
                                                        </Col>
                                                    </Row>
                                                    <Button type="submit" className="medium-btn save-chn-btn"> {location.state.isEdit ? 'Save Changes' : 'Save Changes'}</Button>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="second">
                                    <AdminPropertyView />
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

            </div>




        </>
    )
}

export default NewForm
