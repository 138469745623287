import React from 'react';
import Header from '../Header/Header';
import { Button, Col, Container, Form, FormControl, Image, Row } from 'react-bootstrap';
import Banner from '../../assets/Blogs/blogs-banner.png';
import './Blogs.css';
import BlogTitleImage from '../../assets/Blogs/blogs-title-img.png';
import asideImg1 from '../../assets/SearchResults/aside-img-1.png';
import asideImg2 from '../../assets/SearchResults/aside-img-2.png';
import { Search } from 'lucide-react';
import NewsLayout from './NewsLayout';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
    const articles = [
        {
            id: 1,
            title: 'Exploring the Cultural Diversity of Neighborhoods',
            content: 'Discover diverse neighborhoods to find the perfect community match for your lifestyle needs. Explore cultural richness today!',
            date: 'Mar 30, 2024',
            readTime: '6 min read'
        },
        {
            id: 2,
            title: 'The Rise of Tech in Urban Areas',
            content: 'How technology is reshaping urban landscapes and what it means for the future of city living.',
            date: 'Apr 15, 2024',
            readTime: '5 min read'
        },
        {
            id: 3,
            title: 'Sustainable Living in the City',
            content: 'Tips and tricks for leading a more sustainable lifestyle in an urban environment.',
            date: 'May 10, 2024',
            readTime: '4 min read'
        },
        {
            id: 4,
            title: 'The Rise of Co-Living Spaces',
            content: 'In recent years, urban living has undergone a remarkable transformation with the rise of co-living spaces...',
            date: 'Jun 20, 2024',
            readTime: '5 min read'
        }
    ];

    const [searchQuery, setSearchQuery] = React.useState('');
    const navigate = useNavigate();

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredArticles = articles.filter(article =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const navigateToDetails = (articleId) => {
        navigate(`/blogsDetails/${articleId}`);
    };

    return (
        <div className="blogs-wrapper">
            <Header />
            <section>
                <Container>
                    <div className='article-banner-section'>
                        <Image src={Banner} alt="banner" className='article-banner-img' />
                        <div className='position-relative'>
                            <Form className="search-box-articles">
                                <FormControl
                                    type="text"
                                    placeholder="Search"
                                    className="mr-sm-2"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                                <Button variant="outline-dark"><Search /></Button>
                            </Form>
                        </div>
                    </div>

                    <Row>
                        <Col>
                            {filteredArticles.map((article) => (
                                <div className="article mb-4 cursor-pointer" onClick={() => navigateToDetails(article.id)} key={article.id}>
                                    <div className="image-container">
                                        <img src={BlogTitleImage} alt="BlogTitleImage" />
                                    </div>
                                    <div className="text-content">
                                        <h2>{article.title}</h2>
                                        <p>{article.content}</p>
                                        <div className="article-info">
                                            <span>{article.date} | {article.readTime}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <Row className="mt-5">
                                <Col md={6}><NewsLayout title='Latest News' /></Col>
                                <Col md={6}><NewsLayout title='Whats in hyderabad?' /></Col>
                                <Col md={6}><NewsLayout title='Travel' /></Col>
                                <Col md={6}><NewsLayout title='Food' /></Col>
                            </Row>
                        </Col>

                        <Col className='aside-column pe-0'>
                            <div className='aside-img'>
                                <Image src={asideImg1} alt='aside image' />
                            </div>
                            <div className='aside-img'>
                                <Image src={asideImg2} alt='aside image' />
                            </div>
                            <div className='aside-img'>
                                <Image src={asideImg1} alt='aside image' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Blogs;
