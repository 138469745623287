import React, { useRef, useEffect, useState } from 'react';
import { Image, Form, Button, Tab, Tabs, Nav, Container } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';
import SliderImage from '../../assets/Slider/banner-slider-img.svg'
import Header from "../Header/Header";
import SearchIcon from "../../assets/Icons/search.svg"
// import AdvancedSearchIcon from "../../assets/Icons/advanced-search.svg"
import Select from 'react-select';
import LinearGradientImage from '../../assets/linear-gradient-banner.svg'
import SpecialityOne from '../../assets/Icons/speciality-1.svg'
import SpecialityTwo from '../../assets/Icons/speciality-2.svg'
import CollectionImg1 from "../../assets/who_we_are/img_1.svg"
import CollectionImg2 from "../../assets/who_we_are/img_2.svg"
import CollectionImg3 from "../../assets/who_we_are/img_3.svg"
import CollectionImg4 from "../../assets/who_we_are/img_4.svg"
import './Home.css'
import { useNavigate } from 'react-router-dom';
import CustomSlickSlider from '../Shared/common/CustomSlickSlider/CustomSlickSlider';
import HoverExpandCarousel from '../Shared/common/HoverExpandCarousel/HoverExpandCarousel';
import { ChevronsRight } from 'lucide-react';
import callAPI from '../../commonservices/APIRequests';
import NavSlider from '../Shared/common/NavSlider/NavSlider';
import FlickityCarousel from '../Shared/common/FlickityCarousel/FlickityCarousel';

const Home = (props) => {
    

    const [validated, setValidated] = useState(false)
    const [foramData, setForamData] = useState({ category: 'Lifespaces' })
    const [isCustomError, setIsCustomError] = useState(false)
    const [containerMargin, setContainerMargin] = useState(0);
    const [locations, setLocations] = useState([])
    const containerRef = useRef(null);
    const [luxuryProperty, setLuxuryProperty] = useState([])
    const [isLocation, setIsLocation] = useState(false)
    const navigate = useNavigate()

    const LifespacesOptions = [
        { label: 'Private Room', value: 'Private Room' },
        { label: 'Double Sharing', value: 'Double Sharing' },
        { label: 'Triple Sharing', value: 'Triple Sharing' }
    ]

    const HousingOptions = [
        { label: 'Bungalows', value: 'Bungalows' },
        { label: 'Villas', value: 'Villas' },
        { label: 'Apartments', value: 'Apartments' }
    ]

    const WecationOption = [
        { label: 'Bungalows', value: 'Bungalows' },
        { label: 'Chalets', value: 'Chalets' },
        { label: 'Skivillas', value: 'Skivillas' },
        { label: 'Penthouses', value: 'Penthouses' },
        { label: 'Studio Apartments', value: 'Studio Apartments' }
    ]

    const handleChange = (key, value) => {
        if (key === 'type' && value) {
            setIsCustomError(false)
        }
        setForamData({ ...foramData, [key]: value })
    }
    const handleLocationChange = (selectedOption) => {
        setForamData({ ...foramData, location: selectedOption?.value });
        if (selectedOption) {
            setIsLocation(false); // Reset the location error when a value is selected
        }
    };

    const handleAccommodationChange = (selectedOption) => {
        setForamData({ ...foramData, type: selectedOption?.value });
        if (selectedOption) {
            setIsCustomError(false); // Reset the accommodation type error when a value is selected
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true)
        if (!foramData?.type) {
            setIsCustomError(true)
        }

        if (!foramData?.location) {
            setIsLocation(true)
            return false
        }
        const form = event.currentTarget;
        if (form.checkValidity() !== false && !isCustomError) {
            callAPI.get('/property/getProperties', foramData)
                .then(res => {
                    if (res.status === 200) {
                        setIsCustomError(false)
                        setIsLocation(false)
                        setValidated(false)
                        setForamData({})
                        let data = (res.data?.data?.length > 0) ? res.data.data : []
                        let actionType = (foramData.category === 'Lifespaces') ? 'We-spaces' : (foramData.category === 'We-Cation') ? 'We-cation' : 'We-living'
                        navigate('/searchresults', { state: { searchingData: data, isSearched: true, searchFilter: foramData, actionType: actionType } })
                    } else {
                        setIsLocation(false)
                        setIsCustomError(false)
                        setValidated(false)
                    }
                })
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            // Get the computed left margin of the React Bootstrap container
            const marginLeft = window.getComputedStyle(containerRef.current).marginLeft;
            // Extract the numerical value and convert it to pixels
            const marginLeftPixels = parseInt(marginLeft.replace('px', ''), 10);
            // Set the left margin for the fluid container
            setContainerMargin(marginLeftPixels);
        }

        getLocations()
        expoloreCollections('All')
    }, []);


    const getLocations = () => {
        callAPI.get('/property/getLocations')
            .then(res => {
                if (res.status === 200) {
                    const data = res.data?.data?.map(item => {
                        return {
                            label: item.LocationName,
                            value: item.LocationName
                        }
                    })
                    setLocations(data)
                } else {
                    setLocations([])
                }
            })
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '60px',
            height: '60px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '60px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '60px',
        }),
    }

    const expoloreCollections = (type, actionType) => {
        let req = {
            moduleType: actionType === 'Explore' ? 'collection' : 'tags',
        }

        if (req?.moduleType === 'collection') {
            req.collection = type
        } else {
            req.tags = type
        }

        callAPI.get('/property/getProperties', req)
            .then(res => {
                if (res.status === 200) {
                    if (actionType === 'Explore') {
                        let actionType = type;
                        navigate('/searchresults', { state: { searchingData: res.data?.data ?? [], isSearched: false, searchFilter: req, actionType: actionType } })
                    } else {
                        setLuxuryProperty(res.data?.data ?? [])
                    }
                }
            })
    }

    return (
        <div className="header-banner-wrapper">
            <Header />
            <section className="banner-section">
                <div className="gradient-banner">
                    <img src={LinearGradientImage} />
                </div>
                <div className="banner-section-content">
                    <Container>

                        <div className="banner-content d-flex align-items-end justify-content-between">
                            <div className="banner-left-wrapper">
                                <div className="banner-left-content">
                                    <h1>Find a perfect home you love..!</h1>
                                    <p>Welcome to Weliving. Explore luxury homes with top amenities where your dream
                                        stay is
                                        just a moment away.</p>
                                </div>
                                <div className="banner-slider">
                                    <Carousel>
                                        <Carousel.Item>
                                            <Image src={SliderImage} />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Image src={SliderImage} />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Image src={SliderImage} />
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                            <div className="banner-nav-tabs">
                                <Tabs
                                    defaultActiveKey="Lifespaces"
                                    onSelect={(key) => { setForamData({ category: key }); setIsCustomError(false) }}
                                    id="uncontrolled-tab-example"
                                    className="mb-1"
                                >
                                    <Tab eventKey="Lifespaces" title="We-spaces">
                                        <Form
                                            noValidate
                                            validated={validated}
                                            onSubmit={handleSubmit}
                                        >
                                            <Form.Group className="" controlId="formBasicEmail">
                                                <Select
                                                    isSearchable={true}
                                                    name='Select Location'
                                                    placeholder='Select...'
                                                    className="react-select-dealerlist"
                                                    options={locations || []}
                                                    value={foramData?.location && Array.isArray(locations)
                                                        ? locations.filter(item => item.value === foramData?.location)[0] : null}
                                                        onChange={handleLocationChange}
                                                    styles={customStyles}
                                                    menuPlacement='auto' />
                                                {isLocation ? <p className="custom-error-text">Please select a Location. </p> : null}

                                            </Form.Group>

                                            <Form.Group className="mb-1 mt-4">
                                                <Select
                                                    isSearchable={true}
                                                    className="react-select-dealerlist"
                                                    options={LifespacesOptions ? LifespacesOptions : []}
                                                    value={LifespacesOptions?.filter(item => item.value === foramData?.type)[0] ?? null}
                                                    onChange={value => handleChange('type', value?.value)}
                                                    styles={customStyles}
                                                    menuPlacement='auto' />
                                                {isCustomError ? <p className="custom-error-text">Please Select valid Accommodation Type.</p> : null}
                                            </Form.Group>
                                            {/* <button className="adv-search-btn mt-4 d-flex align-items-center">
                                                <Image src={AdvancedSearchIcon} />
                                                <p className="ms-2 default-blue-text">Advance Search</p>
                                            </button> */}
                                            <Button type="submit" className="d-flex align-items-center justify-content-center search-btn default-btn"> <Image className='me-2' src={SearchIcon} /> Search</Button>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="Housing" title="We-living">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {/* Location Dropdown */}
                <Form.Group className="" controlId="formBasicEmail">
                    <Select
                        isSearchable={true}
                        name="Select Location"
                        placeholder="Select..."
                        className="react-select-dealerlist"
                        options={locations || []}
                        styles={customStyles}
                        value={
                            foramData?.location && Array.isArray(locations)
                                ? locations.filter((item) => item.value === foramData?.location)[0]
                                : null
                        }
                        onChange={handleLocationChange}
                        menuPlacement="auto"
                    />
                    {isLocation && <p className="custom-error-text">Please select a Location. </p>}
                </Form.Group>

                {/* Accommodation Type Dropdown */}
                <Form.Group className="mb-1 mt-4">
                    <Select
                        isSearchable={true}
                        className="react-select-dealerlist"
                        options={HousingOptions ? HousingOptions : []}
                        value={HousingOptions?.filter((item) => item.value === foramData?.type)[0] ?? null}
                        onChange={handleAccommodationChange}
                        styles={customStyles}
                        menuPlacement="auto"
                    />
                    {isCustomError && <p className="custom-error-text">Please Select valid Accommodation Type.</p>}
                </Form.Group>

                {/* Search Button */}
                <Button
                    type="submit"
                    className="d-flex align-items-center justify-content-center search-btn default-btn"
                >
                    <Image className="me-2" src={SearchIcon} /> Search
                </Button>
            </Form>
        </Tab>
        <Tab eventKey="We-Cation" title="We-cation">
    <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
    >
        {/* Location Dropdown */}
        <Form.Group className="" controlId="formBasicEmail">
            <Select
                isSearchable={true}
                name="Select Location"
                placeholder="Select..."
                className="react-select-dealerlist"
                options={locations || []}
                styles={customStyles}
                value={
                    foramData?.location && Array.isArray(locations)
                        ? locations.filter((item) => item.value === foramData?.location)[0]
                        : null
                }
                onChange={(val) => {
                    handleChange('location', val?.value); // Set location value
                    if (val) {
                        setIsLocation(false); // Reset location error on valid selection
                    }
                }}
                menuPlacement="auto"
            />
            {isLocation && <p className="custom-error-text">Please select a Location. </p>}
        </Form.Group>

        {/* Accommodation Type Dropdown */}
        <Form.Group className="mb-1 mt-4">
            <Select
                isSearchable={true}
                className="react-select-dealerlist"
                options={WecationOption ? WecationOption : []}
                value={
                    WecationOption?.filter((item) => item.value === foramData?.type)[0] ?? null
                }
                onChange={(value) => {
                    handleChange('type', value?.value); // Set accommodation type value
                    if (value) {
                        setIsCustomError(false); // Reset accommodation type error on valid selection
                    }
                }}
                styles={customStyles}
                menuPlacement="auto"
            />
            {isCustomError && <p className="custom-error-text">Please Select valid Accommodation Type.</p>}
        </Form.Group>

        {/* Search Button */}
        <Button
            type="submit"
            className="d-flex align-items-center justify-content-center search-btn default-btn"
        >
            <Image className="me-2" src={SearchIcon} /> Search
        </Button>
    </Form>
</Tab>

                                </Tabs>
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
            <section className="explore-collection-section">
                <Container>
                    <div className="cursor-psointer explore-collection d-flex align-items-center justify-content-between">
                        <div>
                            <h2>Explore<br /> Collections</h2>
                        </div>
                        <div className="explore-options">
                            <div className="cursor-pointer">
                                <div onClick={() => expoloreCollections('Friends', 'Explore')}>
                                    <p>Friends</p>
                                </div>
                            </div>
                            <div className="cursor-pointer">
                                <div onClick={() => expoloreCollections('Family', 'Explore')}>
                                    <p>Family</p>
                                </div>
                            </div>
                            <div className="cursor-pointer">
                                <div onClick={() => expoloreCollections('Couples', 'Explore')}>
                                    <p>Couples</p>
                                </div>
                            </div>
                            <div className="cursor-pointer">
                                <div onClick={() => expoloreCollections('Business Stays', 'Explore')}>
                                    <p>Business Stays</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="who-we-are-section">
                <Container
                    ref={containerRef}
                >
                    <div className="heading">
                        <h3 className="section-heading">WHO ARE WE</h3>
                    </div>
                    <div className="d-flex who-we-are-content">
                        <div className="flex-grow-1">
                            <div className="d-flex flex-column">
                                <h3 className="section-title">
                                    Making your home<br /> search easy and fun
                                </h3>
                                <p className="section-description">
                                    Experience the ultimate in luxury living with our curated selection of homes. We
                                    pride
                                    ourselves
                                    on offering top-tier amenities and elegant accommodations to suit your every need.
                                    Discover
                                    the
                                    epitome of comfort and style with us.
                                </p>
                                <div className="specialities d-flex flex-column">
                                    <div>
                                        <Image src={SpecialityOne} alt="icon" />
                                        <h3>Redefine your home search </h3>
                                    </div>
                                    <div>
                                        <Image src={SpecialityTwo} alt="icon" />
                                        <h3>Comfort, Convenience &<br /> Luxury</h3>
                                    </div>
                                    <div>
                                        <Image src={SpecialityTwo} alt="icon" />
                                        <h3>Wide range of housing<br /> options</h3>
                                    </div>
                                </div>
                            </div>
                            <p className="bold-500 cursor-pointer" onClick={() => navigate('/about')}>Read more about us <ChevronsRight size={18} /> </p>
                        </div>
                        <div className="d-flex align-items-center image-gallery">
                            <div className="d-flex flex-column">
                                <Image className="mb-3" alt="img" src={CollectionImg1} />
                                <Image className="mb-3" alt="img" src={CollectionImg2} />
                            </div>
                            <div className="d-flex flex-column ms-3">
                                <Image className="mb-3" alt="img" src={CollectionImg3} />
                                <Image className="mb-3" alt="img" src={CollectionImg4} />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="checkout-new-section">
                <div className="scontainer">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="All">
                        <Container>
                            <div className=" section-header d-flex justify-content-between align-items-center">
                                <div className='mb-3'>
                                    <div className="heading">
                                        <h3 className="section-heading">CHECKOUT OUR NEW</h3>
                                    </div>
                                    <div>
                                        <h3 className="section-title">
                                            Lifespace Properties
                                        </h3>
                                        <p className="section-sub-title">Affordable & Luxury PG/Coliving</p>
                                    </div>
                                </div>
                                <Nav variant="pills">
                                    <Nav.Item onClick={() => expoloreCollections('All')}>
                                        <Nav.Link eventKey="All">All</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => expoloreCollections('Popular')}>
                                        <Nav.Link eventKey="Popular">Popular</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => expoloreCollections('Discounted')}>
                                        <Nav.Link eventKey="Discounted">Discounted</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => expoloreCollections('New-listings')}>
                                        <Nav.Link eventKey="New-listings">New-listings</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </div>
                        </Container>

                        <Tab.Content className='checkout-slider' style={{ marginLeft: containerMargin }}>
                            <Tab.Pane eventKey="All">
                                <div>
                                    <CustomSlickSlider cardData={luxuryProperty} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Popular">
                                <div>
                                    <CustomSlickSlider propertyTag='Popular' cardData={luxuryProperty} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Discounted">
                                <div>
                                    <CustomSlickSlider propertyTag='Discount' cardData={luxuryProperty} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="New-listings">
                                <div>
                                    <CustomSlickSlider propertyTag='New-listings' cardData={luxuryProperty} />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </div>
            </section>

            <div className='d-none'>
                <HoverExpandCarousel />
            </div>

            <div className='d-none'>
                <Container>
                    <NavSlider />
                </Container>
            </div>
            <div className='d-none'>
                <FlickityCarousel />
            </div>

        </div>
    )
}


export default Home