import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Image } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Login.css';
import ArrowWhite from '../../assets/Icons/submit-arrow.svg';
import VerifyOtp from '../VerifyOtp/VerifyOtp';
import callAPI from '../../commonservices/APIRequests';
import { useSelector, useDispatch } from 'react-redux';
import { setIsForgot, userData, userLogin } from '../Store/Shared';

const Login = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLogin, isForgot } = useSelector(store => store.sharedReducer);
    const [foramData, setFormData] = useState({});
    const [signInValidated, setSignInValidated] = useState(false);
    const [signUpvalidated, setSignUpValidated] = useState(false);
    const [verifyOtpShow, setVerifyOtpShow] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false); // Manage the change password modal visibility
    const [formChangePasswordData, setFormChangePasswordData] = useState({});
    const [isMissMatch, setIsMissMatch] = useState(false); // To check password mismatch

    const handleChange = (key, value) => {
        setFormData({ ...foramData, [key]: value });
    };

    const handleChangePassword = (key, value) => {
        setFormChangePasswordData({ ...formChangePasswordData, [key]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (props.activeTab === 'signIn') {
            setSignInValidated(true);
        } else {
            setSignUpValidated(true);
        }
        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            let url = (props.activeTab === 'signIn') ? '/users/login' : '/users/createUser';
            callAPI.post(url, foramData)
                .then(res => {
                    if (res.status === 200) {
                        setFormData({});
                        if (props.activeTab === 'signIn') {
                            dispatch(userLogin(false));
                            toast.success("Login Successfully");
                            dispatch(userData(res.data?.userDetails ?? {}));
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('loginData', JSON.stringify(res.data?.userDetails ?? {}));
                            setSignInValidated(false);
                            props.setIsLogin(true);
                            if (Object.keys(res.data?.userDetails)?.length > 0 && ['admin'].includes(res.data?.userDetails?.role?.toLowerCase())) {
                                navigate('/newAdminPortal', { state: { tabName: 'Properties', isUser: false } });
                            }
                        } else {
                            dispatch(userLogin(false));
                            toast.success("User Created Successfully");
                            dispatch(userData(res.data?.user ?? {}));
                            localStorage.setItem('loginData', JSON.stringify(res.data?.user ?? {}));
                            setVerifyOtpShow(true);
                            setSignUpValidated(false);
                            props.setActiveTab('signIn');
                        }
                    } else {
                        if (props.activeTab === 'signIn') {
                            toast.error(res.message);
                            setSignInValidated(false);
                        } else {
                            toast.error(res.message);
                            setSignUpValidated(false);
                        }
                    }
                });
        }
    };

    const handleCloseForgotPassword = () => {
        // Close the Forgot Password modal and navigate back to the login screen
        setIsChangePassword(false);  // This will close the ChangePassword modal
        props.setActiveTab('signIn');  // Set the active tab to 'signIn'
    };

    const handlePasswordMismatchCheck = () => {
        if (formChangePasswordData?.cpassword !== formChangePasswordData?.password) {
            setIsMissMatch(true);
        } else {
            setIsMissMatch(false);
        }
    };

    return (
        <>
            {/* Login Modal */}
            <Modal className='sign-in-popup' show={isLogin} onHide={() => dispatch(userLogin(false))}>
                <Modal.Body>
                    <Tabs
                        activeKey={props.activeTab}
                        onSelect={(key) => { props.setActiveTab(key); setFormData({}) }}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="signIn" title="SIGN IN">
                            <Form
                                noValidate
                                validated={signInValidated}
                                onSubmit={handleSubmit}
                            >
                                <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                                    <Form.Control
                                        required
                                        type="email"
                                        name='email'
                                        value={foramData?.email ?? ''}
                                        placeholder="Enter email"
                                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Email
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3 mt-3 position-relative password-field" controlId="formBasicPassword">
                                    <Form.Control
                                        required
                                        type="password"
                                        name='password'
                                        value={foramData?.password ?? ''}
                                        placeholder="Password"
                                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Password
                                    </Form.Control.Feedback>
                                    <Button className='position-submit-btn' variant="primary" type="submit" >
                                        <Image src={ArrowWhite} alt='submit' />
                                    </Button>
                                    <p className='mt-2 text-end cursor-pointer' onClick={() => { setIsChangePassword(true) }}>Forgot Password?</p>
                                </Form.Group>

                            </Form>
                        </Tab>
                     
                        <Tab eventKey="signUp" title="SIGN UP">
                            <Form
                                noValidate
                                validated={signUpvalidated}
                                onSubmit={handleSubmit}
                            >

                                <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                                    {/* <Form.Label className='float-left'>Name</Form.Label> */}
                                    <Form.Control
                                        required
                                        type="text"
                                        name='name'
                                        placeholder="Enter Name"
                                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Name
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                                    {/* <Form.Label className='float-left'>Phone Number</Form.Label> */}
                                    <Form.Control
                                        required
                                        type="text"
                                        maxLength={10}
                                        name='phoneNumber'
                                        placeholder="Enter Phone Number"
                                        pattern="\d*"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, '');
                                            handleChange(e.target.name, e.target.value);
                                        }}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Phone Number
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                                    {/* <Form.Label className='float-left'>Email</Form.Label> */}
                                    <Form.Control
                                        required
                                        type="email"
                                        name='email'
                                        placeholder="Enter email"
                                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Email
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3 mt-3 position-relative" controlId="formBasicPassword">
                                    {/* <Form.Label className='float-left'>Password</Form.Label> */}
                                    <Form.Control
                                        required
                                        type="password"
                                        name='password'
                                        placeholder="Password"
                                        onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Password
                                    </Form.Control.Feedback>
                                    <Button className='position-submit-btn' variant="primary" type="submit" >
                                        <Image src={ArrowWhite} alt='submit' />
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>

            {/* Change Password Modal */}
            <Modal className='sign-in-popup' show={isChangePassword} onHide={handleCloseForgotPassword}>
                <Modal.Body>
                    <h3 className="cursor-pointer text-center mb-4">Forgot Password</h3>
                    <Form noValidate validated={signInValidated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                            <Form.Control
                                required
                                type="email"
                                name='email'
                                value={formChangePasswordData?.email ?? ''}
                                placeholder="Enter email"
                                onChange={(e) => handleChangePassword(e.target.name, e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Email
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 mt-3 position-relative password-field" controlId="formBasicPassword">
                            <Form.Control
                                required
                                type="password"
                                name='password'
                                value={formChangePasswordData?.password ?? ''}
                                placeholder="Enter Password"
                                onChange={(e) => handleChangePassword(e.target.name, e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Password
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 mt-3 position-relative password-field" controlId="formBasicCPassword">
                            <Form.Control
                                required
                                type="password"
                                name='cpassword'
                                value={formChangePasswordData?.cpassword ?? ''}
                                placeholder="Confirm Password"
                                onChange={(e) => {
                                    handleChangePassword(e.target.name, e.target.value);
                                    handlePasswordMismatchCheck();  // Check password mismatch
                                }} />
                            {isMissMatch && <p className="text-danger">Passwords do not match!</p>}
                        </Form.Group>

                        <Button type="submit" className="w-100 mt-3" variant="primary">Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Verify OTP Modal */}
            <Modal className='sign-in-popup' show={verifyOtpShow} onHide={() => setVerifyOtpShow(false)}>
                <Modal.Body>
                    <VerifyOtp setVerifyOtpShow={setVerifyOtpShow} setIsLogin={(data) => props.setIsLogin(data)} setActiveTab={(key) => props.setActiveTab(key)} foramData={foramData} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Login;