import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import './NavSlider.css';
import { Button, Image } from "react-bootstrap";
import SliderImgOne from '../../../../assets/Collections/CollectionSliderImages/slider-1.jpg';
import SliderImgTwo from '../../../../assets/Collections/CollectionSliderImages/slider-2.jpg';
import SliderImgThree from '../../../../assets/Collections/CollectionSliderImages/slider-3.jpg';
import SliderImgFour from '../../../../assets/Collections/CollectionSliderImages/slider-4.jpg';
import { useNavigate } from "react-router-dom";
import callAPI from "../../../../commonservices/APIRequests";

function NavSlider() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    const verticalSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        speed: 300, // Increased speed for smoother transition
        cssEase: "cubic-bezier(0.25, 1, 0.5, 1)", // Smoother easing function
        beforeChange: function (currentSlide, nextSlide) {
        },
        afterChange: function (currentSlide) {
        },
        asNavFor: nav2,
        ref: slider => (sliderRef1 = slider),
    };

    const horizontalSettings = {
        asNavFor: nav1,
        ref: slider => (sliderRef2 = slider),
        slidesToShow: 2.3,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        infinite: true,
        speed: 300, // Increased speed for smoother transition
        cssEase: "cubic-bezier(0.25, 1, 0.5, 1)", // Smoother easing function
        centerMode: false,
        centerPadding: '0px', // Adjust the padding as necessary
        beforeChange: function (currentSlide, nextSlide) {
        },
        afterChange: function (currentSlide) {
        },

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    autoplay: false,


                }
            }
        ]
    };

    const expoloreCollections = (type) => {
        const req = {
            moduleType: 'collection',
            collection: type
        }

        callAPI.get('/property/getProperties', req)
            .then(res => {
                if (res.status === 200) {
                    let actionType = type;
                    navigate('/searchresults', { state: { searchingData: res.data?.data ?? [], isSearched: false, searchFilter: req, actionType: actionType } })
                }
            })
    }

    return (
        <div className="slider-container">
            <div className="nav-sliders-wrap">
                <div className="first-slider-nav">
                    <Slider {...verticalSettings}>
                        <div className="d-flex align-items-center inner-slider-wrap">
                            <h3>
                                <h2>Friends</h2>
                                <p>
                                Gather your friends in our Friends Collection, where lively spaces await! Choose from trendy apartments or spacious houses ideal for socializing, with open living areas and close proximity to local attractions.
                                </p>
                                <Button className='collection-explore-btn' onClick={() => expoloreCollections('Friends')} >Explore</Button>
                            </h3>
                        </div>
                        <div className="d-flex align-items-center inner-slider-wrap">
                            <h3>
                                <h2>Family</h2>
                                <p>
                                Our Family Collection features spacious homes with family-friendly amenities, perfect for creating lasting memories together. Enjoy multiple bedrooms and cozy common areas designed for bonding and relaxation.
                                </p>
                                <Button className='collection-explore-btn' onClick={() => expoloreCollections('Family')}>Explore</Button>
                            </h3>
                        </div>
                        <div className="d-flex align-items-center inner-slider-wrap">
                            <h3>
                                <h2>Couples</h2>
                                <p>
                                Escape with your partner in our Couples Collection, offering intimate settings perfect for romance. Enjoy cozy nooks, stunning views, and private spaces designed to enhance your quality time together.
                                </p>
                                <Button className='collection-explore-btn' onClick={() => expoloreCollections('Couples')}>Explore</Button>
                            </h3>
                        </div>
                        <div className="d-flex align-items-center inner-slider-wrap">
                            <h3>
                                <h2>Business stays</h2>
                                <p>
                                Our Business Stays Collection provides convenient accommodations for the modern professional. Each property features high-speed internet and workspaces, ensuring you stay productive while enjoying the comforts of home.
                                </p>
                                <Button className='collection-explore-btn' onClick={() => expoloreCollections('Business Stays')}>Explore</Button>
                            </h3>
                        </div>
                    </Slider>
                </div>
                <div className="second-slider-nav">
                    <Slider {...horizontalSettings}>
                        <div className="cursor-pointer" onClick={() => expoloreCollections('Friends')}>
                            <h3>
                                <Image src={SliderImgThree} fluid />
                            </h3>
                        </div>
                        <div className="cursor-pointer" onClick={() => expoloreCollections('Family')}>
                            <h3>
                                <Image src={SliderImgTwo} fluid />
                            </h3>
                        </div>
                        <div className="cursor-pointer" onClick={() => expoloreCollections('Couples')}>
                            <h3>
                                <Image src={SliderImgFour} fluid />
                            </h3>
                        </div>
                        <div className="cursor-pointer" onClick={() => expoloreCollections('Business Stays')}>
                            <h3>
                                <Image src={SliderImgOne} fluid />
                            </h3>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default NavSlider;
