import React from "react";
import './HoverExpandCarousel.css'

function HoverExpandCarousel() {
    const handleImageHover = (index) => {
        const galleryImages = document.querySelectorAll('.gallery-image');
        galleryImages.forEach((image, i) => {
            if (i === index) {
                image.classList.add('active');
            } else {
                image.classList.remove('active');
            }
        });
    };

    const handleGalleryMouseLeave = () => {
        const galleryImages = document.querySelectorAll('.gallery-image');
        galleryImages.forEach((image) => {
            image.classList.remove('active');
        });
    };

    const images = [
        {
            src: 'https://img.freepik.com/free-photo/rustic-deck-with-patio-furniture-vegetation_23-2150698392.jpg?t=st=1715343147~exp=1715346747~hmac=f81b8940f72aa1b889c0c797d65960b7548338803443e409c38198d984f5b94a&w=360',
        },
        {
            src: 'https://img.freepik.com/free-photo/rustic-patio-furniture-house-deck-with-vegetation_23-2150698280.jpg?t=st=1715343151~exp=1715346751~hmac=a36c76a47d3e1dcd479307b273de424110a12dcad8d046d22fe2824f8b9528e1&w=360',
        },
        {
            src: 'https://img.freepik.com/free-photo/pool_1308-5004.jpg?t=st=1715343143~exp=1715346743~hmac=495c41b88e3d09847391c30c7750921d0366b1b28ed7124a025bb49bb1d47649&w=360',
        },
        {
            src: 'https://img.freepik.com/free-photo/relaxation-pool-umbrella-lounge-sky_1203-4625.jpg?t=st=1715343141~exp=1715346741~hmac=58b2e8c251f478d5150a032db4dc8076d7d0befa48da53c95dd029877afaf0ae&w=360',
        }
    ]

    return (
        <div
            className="custom-image-gallery"
            onMouseLeave={handleGalleryMouseLeave}
        >
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className="gallery-image"
                    onMouseEnter={() => handleImageHover(index)}
                />
            ))}
        </div>
    );
}

export default HoverExpandCarousel;
