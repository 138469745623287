import React, { useEffect, useRef } from 'react';
import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import './FlickityCarousel.css';
import { Image } from 'react-bootstrap';

const FlickityCarousel = ({ slideData }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const flkty = new Flickity(carouselRef.current, {
      // Flickity options
      cellAlign: 'center',
      contain: false,
      imagesLoaded: true,
      freeScroll: true,
      groupCells: '100%',
      initialIndex: 0,
      wrapAround: true,
      // autoPlay: 3000,
      pauseAutoPlayOnHover: false,
      pageDots: false,
      prevNextButtons: true,
      selectedAttraction: 0.01,
      friction: 0.15,
    });

    return () => {
      flkty.destroy();
    };
  }, []);

  return (
    <div ref={carouselRef} className="carousel">
      {slideData && slideData.map((slide, index) => (
        <div className="carousel-cell">
          <Image src={slide.content} fluid />
        </div>
      ))}
    </div>
  );
};

export default FlickityCarousel;
