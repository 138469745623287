import React, { useState, useEffect } from "react"
import { Button, Container, Modal, Form, Image, Row, Col } from 'react-bootstrap'
import callAPI from "../../commonservices/APIRequests"
import NewAdminHeader from "../NewAdminPortal/NewAdminHeader/NewAdminHeader"
import SideNavBar from "../NewAdminPortal/SideNavBar/SideNavBar"
import { Edit, Trash } from 'lucide-react'
import { fileToBase64 } from "../../commonservices/commonMethods"
import moment from "moment"

const Content = () => {

    const [validated, setValidated] = useState(false)
    const [articleModal, setArticleModal] = useState(false)
    const [foramData, setForamData] = useState({})
    const [articleData, setArticleData] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const useLogins = JSON.parse(localStorage.getItem('loginData'))

    useEffect(() => {
        getArticleData()
    }, [])


    const getArticleData = () => {
        callAPI.get('/property/getArticles')
            .then(res => {
                if (res.status === 200) {
                    setArticleData(res.data?.data ?? [])
                } else {

                }
            })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setValidated(true)
        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            callAPI.post('/property/saveAndUpdateArticle', foramData)
                .then(res => {
                    if (res.status === 200) {
                        getArticleData()
                        onclose()
                        setValidated(false)
                    } else {
                        setValidated(false)
                    }
                })
        }
    }

    const handleChange = (key, value) => {
        setForamData({ ...foramData, [key]: value })
    }

    const onclose = () => {
        setArticleModal(false)
        setForamData({})
        setIsEdit(false)
        setValidated(false)
    }

    const editForm = (data) => {
        setForamData({
            ...foramData,
            readTime: data.readTime,
            articleName: data.articleName,
            articleDescription: data.articleDescription,
            articleType: data.articleType,
            articleId: data._id
        })
        setIsEdit(true)
        setArticleModal(true)
    }

    const deleteProperty = (id) => {
        callAPI.post('/property/deleteArticle', { articleId: id })
            .then(res => {
                if (res.status === 200) {
                    let data = articleData?.filter(item => item._id !== id)
                    setArticleData(data)
                } else {

                }
            })
    }

    const uploadFile = async (event) => {
        const file = event.target.files
        let uploadData = []
        for (let i = 0; i < file.length; i++) {
            let document = {
                fileName: file[i].name,
                base64Image: await fileToBase64(file[i]),
                fileType: file[i].type
            }
            uploadData.push(document)
        }
        setForamData({ ...foramData, ['uploadData']: uploadData })

    }
    return (
        <div>

            <div className='d-flex'>
                <SideNavBar />
                <div className='flex-grow-1'>
                    <div className='admin-all-products-wrapper admin-content-wrapper'>
                        {/* <AdminHeader /> */}
                        <NewAdminHeader />
                        <div className='admin-tab-content-box'>
                            <div className='all-products-header-top'>
                                <Container>
                                    <div className='admin-breadcrumb-header d-flex justify-content-between align-items-center'>
                                        <div>
                                            <h2>All Articles</h2>
                                            <p>Home / All Articles</p>
                                        </div>
                                        <div>
                                            <Button className='black-btn-shadow no-shadow' onClick={() => setArticleModal(true)} >ADD NEW Article</Button>
                                        </div>
                                    </div>
                                    <Row>
                                        {articleData?.length > 0 ? articleData.map((item, index) =>
                                            <Col md={3} className='mb-4'>
                                                <div className='property-cards-wrapper'>
                                                    <div className='property-card'>
                                                        <div className='property-brief'>
                                                            <span className='property-img'><Image src={item?.articleImage} alt='property_image' fluid /></span>
                                                            <div>
                                                                {/* <h2>{item.articleType}</h2> */}
                                                                <h5>{item.articleName}</h5>
                                                                {/* <p>{item.articleDescription}</p> */}
                                                                <p>{item.readTime}</p>
                                                                <p>Created At : {moment(item.createdAt).format("MMM DD YYYY")}</p>

                                                                <div>
                                                                    <span className='d-inline-block me-2' onClick={() => editForm(item)}><Edit size={15} /> Edit</span>
                                                                    <span className='d-inline-block' onClick={() => deleteProperty(item._id)}><Trash size={15} /> Delete</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                        ) : null}
                                    </Row>
                                </Container>
                            </div>
                        </div >
                    </div>
                </div>
            </div>

            <Modal className='sign-in-popup' show={articleModal} onHide={() => onclose()}>
                <Modal.Body>
                    {isEdit ? <h4>Update Article</h4> : <h4>Create Article</h4>}
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >

                        <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                            {<Form.Label className='float-left'>Article Name</Form.Label>}
                            <Form.Control
                                required
                                type="text"
                                name='articleName'
                                value={foramData?.articleName ?? ''}
                                placeholder="Enter Article Name"
                                onChange={(e) => handleChange(e.target.name, e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Article Name
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={12} className="mb-1 mt-3" controlId="formBasicEmail">
                            {<Form.Label className="mb-0">Article Description</Form.Label>}
                            <Form.Control
                                as="textarea"
                                required
                                name='articleDescription'
                                value={foramData?.articleDescription ?? ''}
                                placeholder="Enter Article Description"
                                onChange={(e) => handleChange(e.target.name, e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Article Description
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={12} className="mb-1 mt-3" controlId="formBasicEmail">
                            {<Form.Label className="mb-0">Article Type</Form.Label>}
                            <Form.Control as="select"
                                required
                                className="input-color"
                                name='articleType'
                                value={foramData?.articleType ?? ''}
                                onChange={(event) => handleChange(event.target.name, event.target.value)}>
                                <option value=''>Select Article Type</option>
                                <option value='Latest News'>Latest News</option>
                                <option value='Whats in hyderabad?'>Whats in hyderabad?</option>
                                <option value='Travel'>Travel</option>
                                <option value='Food'>Food</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please Select valid Article Type.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                            {<Form.Label className='float-left'>Read Time</Form.Label>}
                            <Form.Control
                                required
                                type="text"
                                name='readTime'
                                value={foramData?.readTime ?? ''}
                                placeholder="Enter Read Time"
                                onChange={(e) => handleChange(e.target.name, e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Read Time
                            </Form.Control.Feedback>
                        </Form.Group>
                        <input type='file' accept=".png, .jpg" onChange={(e) => uploadFile(e)} multiple />
                        {
                            foramData?.uploadData?.length > 0 ? foramData?.uploadData?.map(item => {
                                return <p>{item?.fileName}</p>
                            })

                                : null
                        }
                        <Button variant="primary" type="submit" className="cursor-pointer mr-2">
                            {isEdit ? 'Update' : 'Submit'}
                        </Button>
                        <Button variant="primary" className="cursor-pointer" onClick={() => onclose()}>
                            Cancle
                        </Button>
                    </Form>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default Content
