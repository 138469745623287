import React, { useState, useRef } from 'react';
import './ConfirmBooking.css';
import CustomReactDatePicker from '../Shared/common/CustomReactDatePicker/CustomReactDatePicker';
import Header from '../Header/Header';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import PropImg from '../../assets/order-summary/prop-img.png';
import asideImg1 from '../../assets/SearchResults/aside-img-1.png';
import asideImg2 from '../../assets/SearchResults/aside-img-2.png';
import ThankYouPanda from '../../assets/thanks_giving_bear.svg';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const ConfirmBooking = () => {
    const location = useLocation();
    const printRef = useRef();
    const downloadButtonRef = useRef();

    const handleDownloadPdf = async () => {
        // Hide the download button
        downloadButtonRef.current.style.display = 'none';

        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('download.pdf');

        // Show the download button again
        downloadButtonRef.current.style.display = 'block';
    };

    const useLogins = JSON.parse(localStorage.getItem('loginData'))

    return (
        <div className='confirm-booking-wrapper'>
            <Header />
            <Container>
                <Row className='mx-0'>
                    <Col>
                        <Row>
                            <Col md={12} className='order-summary-wrapper' ref={printRef}>
                                <h2>Booking/Tour Confirmation</h2>


                                <p>Dear, {useLogins?.name ?? ''}</p>

                                <p className='mb-1'>Thank you for choosing Weliving for your rental needs! We're delighted to confirm that your reservation has been successfully processed. Email has been sent to <b>{useLogins?.email ?? ''}</b></p>

                                <p className='my-3 text-uppercase'><b>User ID:</b> USR-{location.state?.bookingData?.userId?.slice(-6) ?? ''}</p>


                                <b className='mb-2 d-inline-block'>Booking Details:</b>

                                <p className='mb-1 text-uppercase'>Property ID: {(location.state?.propertyData?.category ?? '').toLowerCase() === 'we-cation' ? 'WC' : (location.state?.propertyData?.category ?? '').toLowerCase() === 'lifespaces' ? 'WS' : 'WL' }-PROP-{location.state?.propertyData?._id?.slice(-5) ?? ''}</p>

                                <p className='mb-1 text-uppercase'>Booking ID: {(location.state?.bookingData?.addedTo ?? '').toLowerCase() === 'cart' ? 'BKG' : 'REQ'}-2024-{location.state?.bookingData?._id?.slice(-5) ?? ''}</p>
                                
                                <p className='mb-1'>Type: {(location.state?.bookingData?.addedTo ?? '').toLowerCase() === 'cart' ? 'Booking' : 'Requested Tour'}</p>

                                <p className='mb-5'>Booking Date: {(location.state?.bookingData?.addedTo ?? '').toLowerCase() === 'cart' ? moment(location.state?.bookingData?.addedToCartOn).format("MMM DD YYYY") : moment(location.state?.bookingData?.requestedForTourOn).format("MMM DD YYYY") }</p>



                                <Row>
                                    <Col md={6} className='conform-booking'>
                                        <div className='summary-header'>
                                            <div className='property-image'>
                                                <Image src={PropImg} alt='property image' fluid />
                                            </div>
                                            <div className='property-billing flex-grow-1'>
                                                <div className='property-category d-flex justify-content-end'>
                                                    <span>{(location.state?.propertyData?.category === 'Lifespaces') ? 'We-spaces' : (location.state?.propertyData?.category === 'We-Cation') ? 'We-cation' : 'We-living'}</span>
                                                </div>
                                                <div className='billing-details'>
                                                    <h3>{location.state?.propertyData?.propertyName ?? ''}</h3>
                                                    <p>{location.state?.propertyData?.propertyLocation ?? ''}</p>
                                                    <b>₹ {location.state?.propertyData?.monthlyRentForRoom ?? location.state?.propertyData?.price}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='price-details'>
                                            <div className='d-flex justify-content-between'>
                                                <b>Tour Date</b>
                                                <b>{moment(location.state?.propertyData?.date ?? '').format("MMM DD YYYY")}</b>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <b>Rent</b>
                                                <b>₹ {location.state?.propertyData?.monthlyRentForRoom ?? location.state?.propertyData?.price}</b>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <b>Maintainance</b>
                                                <b>₹ {location?.state?.propertyData?.maintananceFee ?? 'N/A'}</b>
                                            </div>
                                            <span className='divider-border'></span>
                                            <div className='d-flex justify-content-between'>
                                                <b>Monthly</b>
                                                <b>₹ {+(location.state?.propertyData?.monthlyRentForRoom ?? location.state?.propertyData?.price ?? 0) + +(location.state?.propertyData?.maintananceFee ?? 0)}</b>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <b className='default-blue-text'>Security Deposit</b>
                                                <b className='default-blue-text'>₹ {location?.state?.propertyData?.securityFee ?? 'N/A'}</b>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} className='text-center thankyou-desc'>
                                        <Image src={ThankYouPanda} />
                                        <h3>Thanks for your booking!</h3>
                                        <p>Thanks for placing an order with Weliving!<br />
                                            Check your mail or whatsapp for order details</p>
                                        <div ref={downloadButtonRef} className='text-center'>
                                            <p className=''>OR</p>
                                            <Button
                                                variant='primary'
                                                className='confirm-tour-btn'
                                                onClick={handleDownloadPdf}
                                            >
                                                Download as PDF
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='aside-column pe-0'>
                        <div className='aside-img'>
                            <Image src={asideImg1} alt='aside image' />
                            <div className="d-flex justify-content-center">
                                <Button className='black-btn-shadow'>explore</Button>
                            </div>
                        </div>
                        <div className='aside-img'>
                            <Image src={asideImg2} alt='aside image' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ConfirmBooking;
