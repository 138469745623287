import React, { useState, useEffect } from 'react';
import './PropertyPage.css'
import Variant_SlickSlider from '../Shared/common/Variant_SlickSlider/Variant_SlickSlider'
import Header from '../Header/Header'
import { Button, Col, Container, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import CCTV from '../../assets/Properties/amenities/cctv-camera.svg'
import Biometric from '../../assets/Properties/amenities/biometric.svg'
import Cleaning from '../../assets/Properties/amenities/cleaning.svg'
import Security from '../../assets/Properties/amenities/security.svg'
import Gym from '../../assets/Properties/amenities/dumbbell-gym.svg'
import Wifi from '../../assets/Properties/amenities/wifi.svg'
import LaundaryIcon from '../../assets/Properties/laundry.svg'
import HeartWhite from '../../assets/Icons/heart_white.svg'
import HeartBlack from '../../assets/Icons/heart_black.svg'
import ShareBlack from '../../assets/Icons/share-black.svg'
import ShareWhite from '../../assets/Icons/share-white.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import callAPI from '../../commonservices/APIRequests';
import { toast } from 'react-toastify';
import CustomSlickSlider from '../Shared/common/CustomSlickSlider/CustomSlickSlider';
import { userLogin } from '../Store/Shared';
import { useSelector, useDispatch } from 'react-redux';

const PropertyPage = () => {

    const { userData } = useSelector(store => store.sharedReducer);
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [showReadMoreData, setShowReadMoreData] = useState(false)
    const [showAll, setShowAll] = useState(false);
    const [slidePopuplarData, setSlidePopuplarData] = useState([])
    const [cardData, setCardData] = useState([])
    const propertyWidths = [500, 200, 600, 300, 225, 175];
    const propertyImages = location?.state?.propertyData?.images;

    const { id: selectedPropertyId } = useParams()


    let imageContent = [];

    for (let i = 0; i < propertyImages?.length; i++) {
        imageContent.push({ content: propertyImages[i], width: propertyWidths[i] });
    }

    const slideData = imageContent ?? [
        { content: "https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: 500 },
        { content: "https://images.pexels.com/photos/70441/pexels-photo-70441.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: 200 },
        { content: "https://images.pexels.com/photos/2395204/pexels-photo-2395204.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: 600 },
        { content: "https://images.pexels.com/photos/1034662/pexels-photo-1034662.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: 300 },
        { content: "https://images.pexels.com/photos/247431/pexels-photo-247431.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: 225 },
        { content: "https://images.pexels.com/photos/1090638/pexels-photo-1090638.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", width: 175 }
    ];

    useEffect(() => {
        getPropertyData('Similar')
        getPropertyData('Popular')
        getLinkPropertyData(selectedPropertyId)
    }, [])


    const getLinkPropertyData = (id) => {
        const req = {
            propertyId: id
        }
        callAPI.get(`/property/getPropertyById`, req)
            .then(res => {
                if (res.status === 200) {
                    navigate(`/property/${id}`, { state: { propertyData: res.data?.data ?? {} } })
                }
            })
    }

    const addToCart = (type) => {
        const req = {
            propertyId: location?.state?.propertyData?._id ?? '',
            addedTo: type,
            paymentAmount: location?.state?.propertyData?.price ?? '',
            userId: userData?._id ?? '',
            scheduledDateForTour: ''
        }
        callAPI.post('/property/addToCart', req)
            .then(res => {
                if (res.status === 200) {
                } else {

                }
            })
    }


    const getPropertyData = (type) => {
        let req = {
            category: location?.state?.propertyData?.category ?? ''
        }
        if (type !== 'Similar') {
            req.moduleType = 'tags'
            req.tags = 'Popular'
        }

        callAPI.get('/property/getProperties', req)
            .then(res => {
                if (res.status === 200) {
                    if (type === 'Similar') {
                        setSlidePopuplarData(res.data?.data ?? [])
                    } else {
                        setCardData(res.data?.data ?? [])
                    }

                } else {
                    if (type === 'Similar') {
                        setSlidePopuplarData([])
                    } else {
                        setCardData([])
                    }
                }
            })
    }

    const copyToClipboard = () => {
        const urlToCopy = window.location.href;

        // Check if the Clipboard API is supported
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(urlToCopy).then(() => {
                toast.success('URL Copied!', { position: 'bottom-right' });
            }).catch(err => {
                fallbackCopyTextToClipboard(urlToCopy);
            });
        } else {
            // Fallback for older browsers or non-secure contexts
            fallbackCopyTextToClipboard(urlToCopy);
        }
    };

    // Fallback method
    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';  // Avoid scrolling to bottom
        textArea.style.left = '-9999px'; // Make it invisible
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            toast.success('URL Copied!', { position: 'bottom-right' });
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            toast.error('Failed to copy URL', { position: 'bottom-right' });
        }

        document.body.removeChild(textArea);
    };

    let amenities = [
        { name: 'CCTV', icon: CCTV },
        { name: 'Wifi', icon: Wifi },
        { name: 'Cleaning', icon: Cleaning },
        { name: 'Laundary', icon: LaundaryIcon },
        { name: 'Biometric', icon: Biometric },
        { name: 'Security', icon: Security },
        { name: 'Gym', icon: Gym }
        // Add more amenities as needed
    ];

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const firstFiveAmenities = amenities.slice(0, 5);
    const remainingAmenities = amenities.slice(5);

    for (let i = 0; i < location?.state?.propertyData?.amenities?.length; i++) {
        const index = firstFiveAmenities.findIndex(amenity => amenity.name === location?.state?.propertyData.amenities[i]);
        if (index > -1) {
            firstFiveAmenities[index].isvisible = true;
        }

        const index02 = remainingAmenities.findIndex(amenity => amenity.name === location?.state?.propertyData.amenities[i]);
        if (index02 > -1) {
            remainingAmenities[index02].isvisible = true;
        }

    }

    const propertyBooking = (type) => {
        if (Object.keys(userData || {})?.length > 0) {
            if (type === 'bookNow') {
                navigate('/ordersummary', { state: { propertyData: location?.state?.propertyData ?? {}, type: 'Book Now' } })
            } else {
                navigate('/ordersummary', { state: { propertyData: location?.state?.propertyData ?? {}, type: 'Request Tour' } })
            }
        } else {
            dispatch(userLogin(true))
        }
    }

    return (
        <div className='property-page-wrapper'>
            <Header />

            <Container>
                <div className="page_header">
                    <p className='d-flex align-items-center justify-content-between'>
                        <p className='property-path'>Home/{location?.state?.propertyData?.category ?? ''}/{location?.state?.propertyData?.propertyLocation ?? ''}/{location?.state?.propertyData?.accommodationType ?? ''}/{location?.state?.propertyData?.propertyName ?? ''}</p>
                        <p className='property-path'>Last updated: {moment(location?.state?.propertyData?.updatedAt ?? new Date()).format("MMM DD YYYY")}</p>
                    </p>
                    <h3 className='page-result-title'>{location?.state?.propertyData?.propertyName ?? ''}</h3>
                </div>
                <div className="variant_slider_wrapper">
                    <Variant_SlickSlider slideData={slideData} />

                </div>
            </Container>
            <section className='bg-F3F3F3 property-section'>
                <section className={`property_desc_section`}>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={11} className='property_details'>
                                <Row className="mx-0 ">
                                    <Col md={8} className="left_section">
                                        <div className='left_section_content'>
                                            <h3>{location?.state?.propertyData?.propertyName ?? ''}</h3>
                                            <h4>{location?.state?.propertyData?.propertyLocation ?? ''}</h4>
                                            <div className='classic-content'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div>
                                                        <p className='label'>{location?.state?.propertyData?.category === 'We-Cation' ? 'Per Night ' : 'Monthly Rent'}</p>
                                                        <p className='value'>
                                                            {location?.state?.propertyData?.category === 'Housing' && (
                                                                <div>
                                                                    {location?.state?.propertyData ? `₹${location?.state?.propertyData?.monthlyRentForRoom ?? location?.state?.propertyData?.price ?? 'N/A'}` : 'N/A'}
                                                                </div>
                                                            )}
                                                            {location?.state?.propertyData?.category === 'We-Cation' && (
                                                                <div>
                                                                    {location?.state?.propertyData ? `₹${location?.state?.propertyData?.price || 'N/A'}` : 'N/A'}
                                                                </div>
                                                            )}
                                                            {location?.state?.propertyData?.category === 'Lifespaces' && (
                                                                <div>
                                                                    {/* {location?.state?.propertyData ? `₹${location?.state?.propertyData?.monthlyRentForBed || 'N/A'}` : 'N/A'} */}
                                                                    {/* - */}
                                                                    {location?.state?.propertyData ? `₹${location?.state?.propertyData?.monthlyRentForRoom || 'N/A'}` : 'N/A'}
                                                                </div>
                                                            )}
                                                        </p>



                                                    </div>
                                                    {['we-cation', 'lifespaces', 'housing'].includes(location?.state?.propertyData?.category?.toLowerCase()) ?
                                                        <div className='text-end'>
                                                            <Button className='request-tour-btn' onClick={() => propertyBooking('bookNow')}>Book Now</Button>
                                                            <p className='label'>Cancellation Policy</p>
                                                        </div> :
                                                        <div className='text-end'>
                                                            <Button className='request-tour-btn' onClick={() => propertyBooking('requestTour')}>Request Tour</Button>
                                                            <p className='label'>Cancellation Policy</p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='property-features'>
                                                    {location?.state?.propertyData?.category !== 'We-Cation' &&
                                                        <>
                                                            <div>
                                                                <p className='label'>Security</p>
                                                                <p className='value'>{location?.state?.propertyData?.securityFee ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Maintanance</p>
                                                                <p className='value'>{location?.state?.propertyData?.maintananceFee ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Available from</p>
                                                                <p className='value'>{moment(location?.state?.propertyData?.availableFrom).format('MMMM D, YYYY')}</p>
                                                            </div>
                                                        </>
                                                    }

                                                    {location?.state?.propertyData?.category === 'Housing' &&
                                                        <>
                                                            <div>
                                                                <p className='label'>Bathrooms</p>
                                                                <p className='value'>{location?.state?.propertyData?.noOfBathRooms ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Balcony</p>
                                                                <p className='value'>{location?.state?.propertyData?.balcony ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Floor</p>
                                                                <p className='value'>{location?.state?.propertyData?.noOfFloors ?? 'N/A'}</p>
                                                            </div>
                                                        </>}
                                                    {location?.state?.propertyData?.category === 'Lifespaces' &&
                                                        <>
                                                            <div>
                                                                <p className='label'>Triple Sharing</p>
                                                                <p className='value'>{location?.state?.propertyData?.tripleSharingPrice ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Shared Room</p>
                                                                <p className='value'>{location?.state?.propertyData?.sharedRoomPrice ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Private Room</p>
                                                                <p className='value'>{location?.state?.propertyData?.privateRoomPrice ?? 'N/A'}</p>
                                                            </div>
                                                        </>}
                                                </div>
                                                <div className='black-divider'>
                                                    <span></span>
                                                </div>

                                                <div className='amenities-row'>
                                                    {firstFiveAmenities.map((amenity, index) => (
                                                        amenity?.isvisible ? (
                                                            <div key={index}>
                                                                <div>
                                                                    <span>
                                                                        <img src={amenity.icon} alt={amenity.name} />
                                                                    </span>
                                                                    <p>{amenity.name}</p>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    ))}
                                                </div>
                                                {showAll && (
                                                    <div className='amenities-row mt-3'>
                                                        {remainingAmenities.map((amenity, index) => (
                                                            amenity?.isvisible ? (
                                                                <div key={index}>
                                                                    <div>
                                                                        <span>
                                                                            <img src={amenity.icon} alt={amenity.name} />
                                                                        </span>
                                                                        <p>{amenity.name}</p>
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}

                                                <p className='show-all-text' onClick={toggleShowAll}>
                                                    {remainingAmenities?.every(v => v.isvisible === true) && `>> Show all amenities`}
                                                </p>


                                                <div className='about-property'>
                                                    <h4>About this property</h4>
                                                    <p className='show-all-text' onClick={() => setShowReadMoreData(!showReadMoreData)}>{!showReadMoreData ? `> > Read More` : `> > Read Less`}</p>
                                                    {showReadMoreData ? <p>{location?.state?.propertyData?.propertyDescription ?? ''}</p> : null}
                                                </div>
                                                <div className='property-details-footer'>
                                                    <span>
                                                        <Image src={HeartWhite} alt='like' onClick={() => addToCart('Wishlist')} />
                                                    </span>
                                                    <OverlayTrigger overlay={<Tooltip>Copy link</Tooltip>}>
                                                        <span onClick={copyToClipboard} className='cursor-pointer'>
                                                            <Image src={ShareWhite} alt='share' className='share-prop-icon' />
                                                        </span>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} className="right_section">
                                        <div className="right_top">
                                            <div>
                                                <h4>Still deciding?</h4>
                                                <p>Shortlist this property for now & easily
                                                    come back to it later.</p>
                                            </div>
                                            <div>
                                                <Image src={HeartBlack} alt='share' onClick={() => addToCart('Wishlist')} />
                                            </div>
                                        </div>
                                        <OverlayTrigger overlay={<Tooltip>Copy link</Tooltip>}>
                                            <div onClick={copyToClipboard} className='cursor-pointer share-btn-lg'><Image src={ShareBlack} /> <p>Share</p></div>
                                        </OverlayTrigger>
                                        <div>
                                            {location?.state?.propertyData?.latitude && location?.state?.propertyData?.longitude ?
                                                <iframe
                                                    width="100%"
                                                    height="100%"
                                                    frameborder="0"
                                                    scrolling="no"
                                                    marginheight="0"
                                                    marginwidth="0"
                                                    src={`https://maps.google.com/maps?q=${location?.state?.propertyData?.latitude},${location?.state?.propertyData?.longitude}&z=15&output=embed`}
                                                    allowfullscreen=""
                                                    aria-hidden="false"
                                                    tabindex="0"
                                                >
                                                </iframe>
                                                : null}
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='similar-properties-section'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={11} className='col-size'>
                                <div className='similar-props-wrapper'>
                                    <h3 className='section-title'>Similar Properties</h3>
                                    <CustomSlickSlider lucideIcons={true} cardData={cardData} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='similar-properties-section popular-properties-section'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={11} className='col-size'>
                                <div className='similar-props-wrapper'>
                                    <h3 className='section-title'>Popular Properties</h3>
                                    <CustomSlickSlider propertyTag='Popular' lucideIcons={true} cardData={slidePopuplarData} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className='d-flex align-items-center justify-content-between mobile-property-footer d-none'>
                    <div>
                        <p className='label'>{location?.state?.propertyData?.category === 'We-Cation' ? 'Per Night ' : 'Monthly Rent'}</p>
                        <p className='value'>
                            {location?.state?.propertyData?.category === 'Housing' && (
                                <div>
                                    {location?.state?.propertyData ? `₹${location?.state?.propertyData?.monthlyRentForRoom ?? location?.state?.propertyData?.price ?? 'N/A'}` : 'N/A'}
                                </div>
                            )}
                            {location?.state?.propertyData?.category === 'We-Cation' && (
                                <div>
                                    {location?.state?.propertyData ? `₹${location?.state?.propertyData?.monthlyRentForRoom ?? location?.state?.propertyData?.price ?? 'N/A'}` : 'N/A'}
                                </div>
                            )}
                            {location?.state?.propertyData?.category === 'Lifespaces' && (
                                <div>
                                    {location?.state?.propertyData ? `₹${location?.state?.propertyData?.monthlyRentForBed || 'N/A'}` : 'N/A'}
                                    -
                                    {location?.state?.propertyData ? `₹${location?.state?.propertyData?.monthlyRentForRoom || 'N/A'}` : 'N/A'}
                                </div>
                            )}
                        </p>
                    </div>
                    <div className='text-end'>
                        <Button className='request-tour-btn' onClick={() => navigate('/ordersummary', { state: { propertyData: location?.state?.propertyData ?? {}, type: 'Book Now' } })}>Book Now</Button>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default PropertyPage