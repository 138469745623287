import React, { useState, useEffect } from 'react'
import callAPI from '../../../commonservices/APIRequests'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import ReactTable from '../../ReactTable/ReactTable'

const Dashboard = (props) => {
    const [bookingData, setBookingData] = useState([])
    const [columns, setColumns] = useState([])


    const sampleData = [{
        propertyId: '123456',
        propertyName: 'mall',
        date: '24-06-2024',
        userName: 'jaya',
        status: 'open'
    },
    {
        propertyId: '123456',
        propertyName: 'house',
        date: '24-06-2024',
        userName: 'krishna',
        status: 'closed'
    },
    {
        propertyId: '123456',
        propertyName: 'villa',
        date: '24-06-2024',
        userName: 'pavan',
        status: 'open'
    }
    ]

    useEffect(() => {
        getBookings()
    }, [])
    useEffect(() => {
        let dataColumns = [
            {
                Header: 'Property Id',
                accessor: 'propertyId',
                filter: 'contains',
                Cell: props => <p>{props.value}</p>
            },
            {
                Header: 'Property Name',
                accessor: 'propertyName',
                filter: 'contains',
                Cell: props => <p>{props.value}</p>
            },
            {
                Header: 'Date',
                accessor: 'date',
                filter: 'contains',
                Cell: props => <p>{props.value}</p>
            },
            {
                Header: 'User Name',
                accessor: 'userName',
                filter: 'contains',
                Cell: props => <p>{props.value}</p>
            },
            {
                Header: 'Status',
                accessor: 'status',
                filter: 'contains',
                Cell: props => <p>{props.value}</p>
            }
        ]
        setColumns(dataColumns)
    }, [bookingData])

    const getBookings = () => {
        callAPI.get('/property/getUserProperties')
            .then(res => {
                if (res.status === 200) {
                    setBookingData(res.data?.bookingData ?? sampleData)
                } else {
                    setBookingData(sampleData)
                }
            })
    }


    return (
        <div className='mt-4 ml-2'>
            <ReactTable
                columns={columns}
                data={bookingData}
            />
        </div>
    )
}

export default Dashboard
