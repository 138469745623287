import React, { useRef, useState } from 'react'
import './OrderSummary.css'
import CustomReactDatePicker from '../Shared/common/CustomReactDatePicker/CustomReactDatePicker'
import Header from '../Header/Header'
import { Button, Col, Container, Image, Row, Form } from 'react-bootstrap'
import PropImg from '../../assets/order-summary/prop-img.png'
import asideImg1 from '../../assets/SearchResults/aside-img-1.png'
import asideImg2 from '../../assets/SearchResults/aside-img-2.png'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment';
import { toast } from 'react-toastify';
import callAPI from '../../commonservices/APIRequests'
import { fileToBase64 } from '../../commonservices/commonMethods'

const OrderSummary = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const [date, setDate] = useState({ startDate: new Date(), endDate: null })
    const [dateCount, setDateCount] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [formData, setFormData] = useState({})
    const [isAddclick, setIsAddclick] = useState(false)
    const [requiredPhoto, setRequiredPhoto] = useState(false)
    const [requiredPNumber, setRequiredPNumber] = useState(false)
    const totalAmout = (+location?.state?.propertyData?.price ?? 0) + (+location?.state?.propertyData?.mainTainance ?? 0)
    const useLogins = JSON.parse(localStorage.getItem('loginData'))
    const sectionRef = useRef(null);
    const [activeReqFields, setActionReqFields] = useState(false);


    const addToCart = (type) => {
        let required = false
        if (!formData?.phoneNumber) {
            setRequiredPNumber(true)
            required = true
        }
        if (!formData?.profilePhoto?.fileName) {
            setRequiredPhoto(true)
            required = true
        }

        if (!required) {
            const req = {
                propertyId: location?.state?.propertyData?._id ?? '',
                addedTo: ['we-cation', 'lifespaces', 'housing'].includes(location?.state?.propertyData?.category?.toLowerCase()) ? 'Cart' : 'Requested_For_Tour',
                paymentAmount: location?.state?.propertyData?.price ?? '',
                userId: useLogins?._id ?? '',
                uploadData: [formData?.profilePhoto] ?? [],
                phoneNumber: formData?.phoneNumber ?? ''
            }
            if (['we-cation', 'lifespaces', 'housing'].includes(location?.state?.propertyData?.category?.toLowerCase())) {
                req.startDate = date?.startDate ? moment(date.startDate).format("MM-DD-YYYY") : ''
                req.endDate = date?.endDate ? moment(date.endDate).format("MM-DD-YYYY") : ''
            } else {
                req.scheduledDateForTour = date?.startDate ? moment(date.startDate).format("MM-DD-YYYY") : ''
            }
            callAPI.post('/property/addToCart', req)
                .then(res => {
                    if (res.status === 200) {
                        setRequiredPNumber(false)
                        setRequiredPhoto(false)
                        toast.success('Your tour has been successfully confirmed');
                        let data = { ...location?.state?.propertyData }
                        data.date = date
                        navigate('/confirmbooking', { state: { bookingData: res.data?.data ?? '', propertyData: data } })
                    } else {
                        toast.error(res.message);
                    }
                })
        }
    }

    const handleChange = (value) => {
        setDate(value)
        let dayCount = 1
        if (value?.endDate && value?.startDate) {
            const timeDifference = value?.endDate?.getTime() - value?.startDate?.getTime();
            dayCount = Math.ceil(timeDifference / (1000 * 3600 * 24));
            setDateCount(dayCount)
        }
        if (location?.state?.propertyData?.category === 'We-Cation') {
            let count = location.state.propertyData?.price * dayCount
            let Fee = +location?.state?.propertyData?.securityFee || 0
            let totalCount = count + Fee
            setTotalCount(totalCount)
        }
    }

    const addDetails = async (key, value, event) => {
        if (key === 'profilePhoto') {
            const file = event.target.files[0]
            let document = {
                fileName: file?.name,
                base64Image: await fileToBase64(file),
                fileType: file?.type
            }
            setFormData({ ...formData, [key]: document })
            setRequiredPhoto(false)
        } else {
            setFormData({ ...formData, [key]: value })
            setRequiredPNumber(false)
        }
    }


    const handleScroll = () => {
        const offset = 20;
        const topPos = sectionRef.current.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
            top: topPos,
            behavior: 'smooth',
        });
        setActionReqFields(true)
    };

    return (
        <div className='order-summary-page-wrapper'>
            <Header />
            <Container>
                <Row className='mx-0'>
                    <Col className='order-summary-left'>
                        <Row>
                            <Col md={5}>
                                <div className='schedule-date'>
                                    <CustomReactDatePicker
                                        selectsRange={(location?.state?.type === 'Book Now') && ['we-cation'].includes(location?.state?.propertyData?.category?.toLowerCase()) ? true : false}
                                        minDate={true}
                                        date={new Date(date)}
                                        handleScroll={handleScroll}
                                        handleChange={(value) => handleChange(value)} />
                                </div>
                                <div className='order-terms'>
                                    <div className='cancellation-policy'>
                                        <h3>Cancellation policy</h3>
                                        <p>Cancel before check-in on 16 Apr for a partial refund. After that, your refund depends on when you cancel. <span className='bold-and-underline'>Learn more</span></p>
                                    </div>
                                    <div className='ground-rules'>
                                        <h3>Ground Rules</h3>
                                        <p>We ask every guest to remember a few simple things about what makes a great guest.</p>
                                        <ul className='rules-ul'>
                                            <li>Follow the house rule</li>
                                            <li>Treat your Host's home like your own</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className='order-summary-wrapper'>
                                <div>
                                    <div className='summary-header'>
                                        <div className='property-image'>
                                            <Image src={PropImg} alt='property image' fluid />
                                        </div>
                                        <div className='summary-property-details'>
                                            <div className='property-category d-flex justify-content-end'>
                                                <span>{location?.state?.propertyData?.category ?? ''}</span>
                                            </div>
                                            <div className='billing-details'>
                                                <h3 className='line-clamp'>{location?.state?.propertyData?.propertyName ?? ''}</h3>
                                                <p>{location?.state?.propertyData?.propertyLocation ?? ''}</p>
                                                {location?.state?.propertyData?.category === 'Housing' ?
                                                    <b>₹ {location?.state?.propertyData?.monthlyRentForRoom ?? ''}</b> :
                                                    (location?.state?.propertyData?.category === 'We-Cation') ?
                                                        <b>₹ {location?.state?.propertyData?.price ?? ''}</b> :
                                                        <b>₹ {location?.state?.propertyData?.monthlyRentForRoom ?? ''}</b>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                    <div>
                                        <div className='price-details'>
                                            <div className='d-flex justify-content-between'>
                                                <b>Tour Date</b>
                                                <b>{date?.startDate ? moment(date.startDate).format("MMM DD YYYY") : ''} {date?.endDate ? 'to ' + moment(date.endDate).format("MMM DD YYYY") : ''}</b>
                                            </div>


                                            {location?.state?.propertyData?.category === 'We-Cation' ?
                                                <>
                                                    <div className='d-flex justify-content-between'>
                                                        <b>{location?.state?.propertyData?.price ?? ''} * {dateCount} Nights</b>
                                                        <b>₹ {location.state.propertyData?.price ? (location.state.propertyData.price * dateCount) : ''}</b>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <b>Service Fees</b>
                                                        <b>₹ {location.state.propertyData?.securityFee ? (location.state.propertyData?.securityFee) : ''}</b>
                                                    </div>
                                                    <span className='divider-border'></span>
                                                    <div className='d-flex justify-content-between'>
                                                        <b>Total</b>
                                                        <b>₹ {totalCount ? totalCount : (location.state?.propertyData?.price * dateCount) + (location.state?.propertyData?.securityFee)}</b>
                                                    </div> </> :
                                                (['Lifespaces', 'Housing'].includes(location?.state?.propertyData?.category)) ? <>

                                                    <div className='d-flex justify-content-between'>
                                                        <b>Rent</b>
                                                        <b>₹ {location.state.propertyData?.monthlyRentForRoom ? (location.state.propertyData?.monthlyRentForRoom) : ''}</b>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <b>Maintainance</b>
                                                        <b>₹ {location?.state?.propertyData?.maintananceFee ?? ''}</b>
                                                    </div>
                                                    <span className='divider-border'></span>
                                                    <div className='d-flex justify-content-between'>
                                                        <b>Monthly</b>
                                                        <b>₹ {(location.state?.propertyData?.monthlyRentForRoom * dateCount) + (location.state?.propertyData?.maintananceFee)}</b>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <b className='default-blue-text'>Security Deposit</b>
                                                        <b className='default-blue-text'>₹ {location?.state?.propertyData?.securityFee ?? 'N/A'}</b>
                                                    </div>
                                                </> : ''}

                                        </div>
                                        <div className='mb-4 apply-offer-fields'>
                                            <div className='mb-3'><b>Apply Offer</b></div>
                                            <input type="text" className='w-100' value="Offer Code" />
                                        </div>
                                        <div className='trip-required-details' ref={sectionRef}>
                                            <h4 className='card-heading mb-3'>Required for your trip</h4>
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center mb-2'>
                                                    <div>
                                                        <h4>Profile Photo</h4>
                                                        <p>Hosts want to know who's statying at their place.</p>
                                                    </div>
                                                    <Button className={`cursor-pointer ${activeReqFields ? 'active default-blue-bg' : ''}`}>
                                                        <input className='cursor-pointer profile_photo' accept='.jpg , .png' type='file' onChange={(event) => addDetails('profilePhoto', null, event)} />
                                                        Add</Button>
                                                </div>
                                                {requiredPhoto ? <p className="custom-error-text">Please Upload Profile Photo</p> : <p>{formData?.profilePhoto?.fileName ?? ''}</p>}
                                            </div>
                                            <div>
                                                <div className={`d-flex justify-content-between align-items-center mb-2 ${isAddclick ? 'active-phone-number' : ''}`}>
                                                    <div>
                                                        <h4>Phone Number</h4>
                                                        <p>Add and confirm your phone number to get trip updates.</p>
                                                    </div>
                                                    {!isAddclick ? <Button className={`cursor-pointer ${activeReqFields ? 'active default-blue-bg' : ''}`} onClick={() => setIsAddclick(true)}>Add</Button> :
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            maxLength={10}
                                                            name='phoneNumber'
                                                            placeholder="Enter Phone Number"
                                                            pattern="\d*"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                                addDetails(e.target.name, e.target.value);
                                                            }}

                                                        />
                                                    }

                                                </div>
                                                {requiredPNumber ? <p className="custom-error-text">Please Enter Phone Number</p> : null}
                                            </div>
                                        </div>
                                        <div className='summary-confirmation'>
                                            <p>By selecting the button below, I agree to the <span className='bold-underline'>Host's House Rules, Ground rules for guests, Airbnb's Rebooking and Refund Policy</span> and that Airbnb can <span className='bold-underline'>charge my payment method</span> if I'm responsible for damage.</p>
                                        </div>
                                        <Button variant='primary' className='confirm-tour-btn' onClick={() => addToCart()}>{['we-cation'].includes(location?.state?.propertyData?.category?.toLowerCase()) ? ' Confirm and Pay' : 'Confirm Tour'}</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='aside-column pe-0'>
                        <div className='aside-img'>
                            <Image src={asideImg1} alt='aside image' />
                            <div className="d-flex justify-content-center">
                                <Button className='black-btn-shadow'>explore</Button>
                            </div>
                        </div>
                        <div className='aside-img'>
                            <Image src={asideImg2} alt='aside image' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OrderSummary