import React, { useState, useEffect } from 'react'
import './AdminPropertyView.css'
import { Button, Container, Modal, Form, Image, Row, Col } from 'react-bootstrap'
import callAPI from '../../../commonservices/APIRequests'
import { useNavigate } from 'react-router-dom';
import { PlusCircle } from 'lucide-react'
import NewAdminHeader from '../../NewAdminPortal/NewAdminHeader/NewAdminHeader'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import MoreHoriz from "../../../assets/Icons/more-horizontal.svg"
import DeleteIcon from "../../../assets/Icons/DeleteIcon.svg"
import EditIcon from "../../../assets/Icons/EditIcon.svg"
const AdminPropertyView = (props) => {

    let data = [{
        propertyName: 'house',
        propertyLocation: 'hyd',
        category: 'own',
        accommodationType: 'sharing',
        propertyDescription: 'girls hostel',
        propertyType: 'hostal',
        amenities: 'amenities',
        bhkType: '1bhk',
        noOfBathRooms: '2',
        noOfFloors: '1',
        tripleSharingPrice: '4000',
        sharedRoomPrice: '6000',
        privateRoomPrice: '10000',
        pricePerDay: '1000',
        maintananceFee: '100',
        monthlyRentForRoom: '5000',
        securityFee: '20000'
    },
    {
        propertyName: 'house',
        propertyLocation: 'hyd',
        category: 'own',
        accommodationType: 'sharing',
        propertyDescription: 'girls hostel',
        propertyType: 'hostal',
        amenities: 'amenities',
        bhkType: '1bhk',
        noOfBathRooms: '2',
        noOfFloors: '1',
        tripleSharingPrice: '4000',
        sharedRoomPrice: '6000',
        privateRoomPrice: '10000',
        pricePerDay: '1000',
        maintananceFee: '100',
        monthlyRentForRoom: '5000',
        securityFee: '20000'
    },
    {
        propertyName: 'house',
        propertyLocation: 'hyd',
        category: 'own',
        accommodationType: 'sharing',
        propertyDescription: 'girls hostel',
        propertyType: 'hostal',
        amenities: 'amenities',
        bhkType: '1bhk',
        noOfBathRooms: '2',
        noOfFloors: '1',
        tripleSharingPrice: '4000',
        sharedRoomPrice: '6000',
        privateRoomPrice: '10000',
        pricePerDay: '1000',
        maintananceFee: '100',
        monthlyRentForRoom: '5000',
        securityFee: '20000'
    }
    ]
    const [isNewProduct, setIsNewProduct] = useState(false)
    const [propertyData, setPropertyData] = useState([])
    const [formData, setFormData] = useState('')
    const [isSelected, SetIsSelected] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = () => {
        // let isSubmited = false

        // if (formData !== '') {
        //     isSubmited = true
        // } else {
        //     SetIsSelected(true)
        // }
        // if (isSubmited) {
        navigate('/newForm', { state: { type: props.tabName, isEdit: false } })
        //     isSubmited = false
        //     SetIsSelected(false)
        //     setFormData({})
        // }

    }

    useEffect(() => {
        getPropertyData()
    }, [props.tabName])


    const getPropertyData = () => {
        let req = {
            category: (props.tabName === 'Welifespaces') ? 'Lifespaces' : (props.tabName === 'Weliving') ? 'Housing' : (props.tabName === 'Wecation') ? 'We-Cation' : props.tabName
        }
        callAPI.get('/property/getProperties', (props.tabName === 'Properties' ? '' : req))
            .then(res => {
                if (res.status === 200) {
                    setPropertyData(res.data?.data ?? data)
                } else {
                    setPropertyData([])
                }
            })
    }

    const getPropertyDataById = (id, viewPage = false) => {
        const req = {
            propertyId: id
        }
        callAPI.get('/property/getPropertyById', req)
            .then(res => {
                if (res.status === 200) {

                    let type = ''
                    switch (res.data?.data?.category) {
                        case 'Housing':
                            type = 'Weliving'
                            break;
                        case 'Lifespaces':
                            type = 'Welifespaces'
                            break;
                        case 'We-Cation':
                            type = 'Wecation'
                            break;
                        default:
                            break;
                    }
                    if (viewPage) { return navigate(`/adminPropertyView/${id}`) }
                    navigate('/newForm', { state: { formData: res.data?.data ?? {}, type: type, isEdit: true } })
                }
            })

    }

    const handleChange = (value) => {
        setFormData(value)
    }

    const editForm = (id) => {
        getPropertyDataById(id)
    }

    const deleteProperty = (id) => {
        let req = {
            propertyId: id
        }
        callAPI.post('/property/deleteProperty', req)
            .then(res => {
                if (res.status === 200) {
                    data = data.filter(item => item._id !== id)
                    getPropertyData()
                } else {
                    //console.log(res)
                }
            })
    }
    return (
        <div className='admin-all-products-wrapper admin-content-wrapper'>
            {/* <AdminHeader /> */}
            <NewAdminHeader />
            <div className='admin-tab-content-box'>
                <div className='all-products-header-top'>
                    <Container>
                        <div className='admin-breadcrumb-header d-flex justify-content-between align-items-center'>
                            <div>
                                <h2>All Products</h2>
                                {/* <p>Home / All Products / {props.tabName}</p> */}
                                <p>Home / {props.tabName === "Welifespaces" ? "Wespaces" : props.tabName}</p>
                            </div>
                            <div>
                                {(props?.tabName === 'Properties') ? null : <Button className='add-new-prop-btn default-btn no-shadow' onClick={() => handleSubmit()}><PlusCircle size={15} className='me-2' /> ADD NEW PROPERTY</Button>}
                            </div>
                        </div>
                        <Row>
                            {propertyData?.map((item, index) =>
                                <Col md={4} className='mb-4' >
                                    <div className='property-cards-wrapper position-relative'>
                                        <div className='property-card' onClick={() => getPropertyDataById(item._id, true)}>
                                            <div className='property-brief'>
                                                <div className='flex-grow-1 d-flex'>
                                                    <span className='property-img'><Image src={item?.propertyImage} alt='property_image' fluid /></span>
                                                    <div>
                                                        <h3 className='property-name'>{item.propertyName}</h3>
                                                        <p className='property-id'>{item.property_Id}</p>
                                                        <p>₹{item?.monthlyRentForRoom ?? item?.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='location-availability'>
                                                <p>{item.propertyLocation}</p>
                                                <p>{(item.noOfBedsAvailable || item.noOfBedsAvailable === 0) ? `Available Beds - ${String(item.noOfBedsAvailable)}` : null}</p>
                                            </div>
                                            <div>
                                                <div className={`vacant-occupied-status cursor-pointer ${item?.roomAvailability === 'Occupied' ? 'occupied' : 'vacant'}`}>
                                                    <p>{item?.roomAvailability ? item.roomAvailability : 'Vacant'}</p>
                                                </div>
                                            </div>
                                            {/* <div className='property-summary'>
                                                <h4>Summary</h4>
                                                <p className=''>{item.propertyDescription}</p>
                                            </div>
                                            <div className='sales-and-demand'>
                                                <div className='sales'>{item.bhkType}</div>
                                                <div className='demand'>{item.noOfFloors}</div>
                                            </div> */}
                                        </div>

                                        <div className='propertyactions'>
                                            <DropdownButton id="dropdown-basic-button" title={<><Image src={MoreHoriz} alt="more-icon" /> </>}>
                                                <Dropdown.Item onClick={() => editForm(item._id)}><Image src={EditIcon} alt="edit-icon" /> </Dropdown.Item>
                                                <Dropdown.Item onClick={() => deleteProperty(item._id)}><Image src={DeleteIcon} alt="edit-icon" /> </Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </div>

                                </Col>
                            )}
                        </Row>


                        <Modal className='' show={isNewProduct} onHide={() => setIsNewProduct(false)}>
                            <Modal.Body>
                                <h3 className='mb-4 text-center'>Select property type for creation</h3>
                                <Form className='mb-3'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        {/* <p>Housing</p> */}
                                        <Form.Check
                                            inline
                                            label="Housing"
                                            type='radio'
                                            name="group1"
                                            value="hosing"
                                            checked={formData === 'hosing'}
                                            onChange={event => handleChange(event.target.value)}
                                        />
                                        {/* <p>Lifespaces</p> */}
                                        <Form.Check
                                            inline
                                            label="Lifespaces"
                                            name="group1"
                                            type='radio'
                                            value="lifeSpaces"
                                            checked={formData === 'lifeSpaces'}
                                            onChange={event => handleChange(event.target.value)}
                                        />
                                        {/* <p>We-Cation</p> */}
                                        <Form.Check
                                            inline
                                            label="We-Cation"
                                            name="group1"
                                            type='radio'
                                            value="weCation"
                                            checked={formData === 'weCation'}
                                            onChange={event => handleChange(event.target.value)}
                                        />
                                    </div>
                                    {isSelected ? <p className='error-text mb-3 text-center'>Please Select Option</p> : null}
                                </Form>
                                <div className='text-center'>
                                    <Button className='black-btn-shadow no-shadow medium-btn' onClick={() => handleSubmit()}>Submit</Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                </div>
            </div >
        </div >
    )
}

export default AdminPropertyView