import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import PropertyImg from '../../../assets/SearchResults/img1.png';
import mapIcon from '../../../assets/SearchResults/mapIcon.svg';
import asideImg1 from '../../../assets/SearchResults/aside-img-1.png'
import asideImg2 from '../../../assets/SearchResults/aside-img-2.png'
import bhkIcon from '../../../assets/SearchResults/bhk-icon.svg';
import KeyIcon from '../../../assets/SearchResults/key-icon.svg';
import RatingIcon from '../../../assets/SearchResults/rating-icon.svg';
import AreaIcon from '../../../assets/SearchResults/area-size.svg';
import Header from '../../Header/Header';
import './CollectionPage.css'
import callAPI from '../../../commonservices/APIRequests';
import { useLocation } from 'react-router-dom';

const CollectionPage = () => {

	const [collectionData, setCollectionData] = useState([])


	const title = ''
	switch ('Bachelors') {
		case "bachelors":
			title = "Bachelors";
			break;
		case "family":
			title = "Family";
			break;
		case "couples":
			title = "Couples";
			break;
		case "business":
			title = "Business";
			break;
	}

	return (
		<div className='collection-page-wrapper default-aside-col'>
			<Header />
			<div>
				<Container>

					<div>
						<Row>
							<Col>
								<Row className='search-result-grid-view mx-0'>
									{collectionData.length > 0 ? collectionData.map(item => {
										return <Col md={6} className='ps-0'>
											<div className='cursor-pointer result-card' >
												<div className='img-shadow'>
													<Image src={PropertyImg} className='w-100' />
												</div>
												<div className='property-img-overlay'>
													<h4>
														₹ 25,000
													</h4>
													<h5>Apartment</h5>
												</div>
												<div className='property-details'>
													<h4 className="line-clamp">Meraas Le Pont Apartments</h4>
													<p><Image src={mapIcon} alt='map' className='me-1' />Hyderabad</p>
													<Row className='property-card-footer'>
														<Col md={6}>
															<p><Image src={bhkIcon} alt='bed icon' className='me-1' />2 BHK</p>
														</Col>
														<Col md={6}>
															<p><Image src={AreaIcon} alt='bed icon' className='me-1' />1200 Sft</p>
														</Col>
														<Col md={6}>
															<p><Image src={KeyIcon} alt='bed icon' className='me-1' />-NA-</p>
														</Col>
														<Col md={6}>
															<p><Image src={RatingIcon} alt='bed icon' className='me-1' />4.5</p>
														</Col>
													</Row>
												</div>
											</div>
										</Col>
									}) : null
									}
								</Row>
							</Col>
							<Col className='aside-column pe-0'>
								<div className='aside-img'>
									<Image src={asideImg1} alt='aside image' />
									<div className="d-flex justify-content-center">
										<Button className='black-btn-shadow'>explore</Button>
									</div>
								</div>
								<div className='aside-img'>
									<Image src={asideImg2} alt='aside image' />
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</div>
	)
}

export default CollectionPage