import React from 'react'
import BlogNewsTitleImg from '../../assets/Blogs/blog-new-img.png'
import { Image } from 'react-bootstrap'

const NewsLayout = (props) => {
    return (
        <div className='new-layout-card mb-4 cursor-pointer'>
            <h3>{props.title}</h3>
            <div className='d-flex news-content-wrap mb-4'>
                <Image src={BlogNewsTitleImg} alt="blog-news-title-img" />
                <div className='news-content'>
                    <h4>Exploring the Cultural Diversity of Neighborhoods:</h4>
                    <p>Discover diverse neighborhoods to find the perfect community match for your lifestyle needs. Explore cultural richness today!</p>
                    <span>Mar 30, 2024 | 6 min read</span>
                </div>
            </div>
            <div className='d-flex news-content-wrap'>
                <Image src={BlogNewsTitleImg} alt="blog-news-title-img" />
                <div className='news-content'>
                    <h4>Exploring the Cultural Diversity of Neighborhoods:</h4>
                    <p>Discover diverse neighborhoods to find the perfect community match for your lifestyle needs. Explore cultural richness today!</p>
                    <span>Mar 30, 2024 | 6 min read</span>
                </div>
            </div>
        </div>
    )
}

export default NewsLayout