import React, { useState, useEffect } from 'react';
import './SearchResults.css';
import Header from '../Header/Header';
import { Button, Col, Image, Row } from 'react-bootstrap';
import mapIcon from '../../assets/SearchResults/mapIcon.svg';
import asideImg1 from '../../assets/SearchResults/aside-img-1.png';
import asideImg2 from '../../assets/SearchResults/aside-img-2.png';
import bhkIcon from '../../assets/SearchResults/bhk-icon.svg';
import RatingIcon from '../../assets/SearchResults/rating-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import callAPI from '../../commonservices/APIRequests';

const SearchResults = () => {
    const [layoutSwitch, setLayoutSwitch] = useState(false)
    const [searchResults, setSearchResults] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const data = (location.state?.searchingData?.length > 0) ? location.state.searchingData : [];
        setSearchResults(data);
    }, [location.state?.searchingData]);



    const getPropertyData = (id) => {
        const req = {
            propertyId: id
        }
        callAPI.get('/property/getPropertyById', req)
            .then(res => {
                if (res.status === 200) {
                    // toast.success(res.data?.message ?? '');
                    navigate(`/property/${req.propertyId}`, { state: { propertyData: res.data?.data ?? {} } })
                }
            })

    }

    return (
        <div>
            <Header />
            <section className='search-results-wrapper'>
                <div className='container'>
                    <Row className='mx-0'>
                        <Col className='ps-0 search-result-content'>
                            {!location?.state?.isSearched ? <p className='property-path page-title-path'>Home/{location?.state?.actionType}</p> :
                                <p className='property-path page-title-path'>Home/{location?.state?.actionType}/{location?.state?.searchFilter?.location}/{location?.state?.searchFilter?.type}</p>}
                            {!location?.state?.isSearched ? <h3 className='property-counts page-result-title'>{searchResults?.length} Properties</h3> : <h3 className='property-counts page-result-title'>{searchResults?.length} Properties in <span className='text-underline'>{location?.state?.searchFilter?.location ?? ''}</span></h3>}
                            <div className='search-result-window'>
                                <Row className='search-result-grid-view mx-0'>
                                    {searchResults.map(item => (
                                        <>
                                            <Col md={4} className='ps-0'>
                                                <div className='cursor-pointer result-card' key={item.id} onClick={() => getPropertyData(item?._id ?? '')}>
                                                    <div className='img-shadow'>
                                                        <Image src={item.propertyImage} className='w-100' />
                                                    </div>
                                                    {item.category === 'Housing' && (
                                                        <div className='property-img-overlay'>
                                                            <h4>
                                                                {`${item.monthlyRentForRoom ? `₹ ${item.monthlyRentForRoom}` : ''}`}
                                                            </h4>
                                                            <h5>{item.accommodationType}</h5>
                                                        </div>
                                                    )}
                                                    {item.category === 'We-Cation' && (
                                                        <div className='property-img-overlay'>
                                                            <h4>
                                                                {`${item.price ? `₹ ${item.price}` : ''}`}
                                                            </h4>
                                                            <h5>{item.accommodationType}</h5>
                                                        </div>
                                                    )}
                                                    {item.category === 'Lifespaces' && (
                                                        <div className='property-img-overlay'>
                                                            <h4>
                                                                {`${item.monthlyRentForBed ? `₹ ${item.monthlyRentForBed}/bed - ` : ''}${item.monthlyRentForRoom ? `₹ ${item.monthlyRentForRoom}/room` : ''}`}
                                                            </h4>
                                                            <h5>{item.accommodationType}</h5>
                                                        </div>
                                                    )}
                                                    <div className='property-details'>
                                                        <h4 className="line-clamp">{item.propertyName}</h4>
                                                        <p><Image src={mapIcon} alt='map' className='me-1' />{item.propertyLocation}</p>
                                                        <Row className='property-card-footer'>
                                                            {item?.bhkType && <Col md={6}>
                                                                <p><Image src={bhkIcon} alt='bed icon' className='me-1' />{item.bhkType} BHK</p>
                                                            </Col>}

                                                            <Col md={6}>
                                                                <p><Image src={RatingIcon} alt='bed icon' className='me-1' />4.5</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                        <Col className='aside-column pe-0'>
                            <div className='aside-img'>
                                <Image src={asideImg1} alt='aside image' />
                                <div className="d-flex justify-content-center">
                                    <Button className='black-btn-shadow'>explore</Button>
                                </div>
                            </div>
                            <div className='aside-img'>
                                <Image src={asideImg2} alt='aside image' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    );
};

export default SearchResults;
