import { combineReducers } from 'redux';
import sharedReducer from './Shared';
import { configureStore } from '@reduxjs/toolkit'


const rootReducer = combineReducers({
    sharedReducer
});


export default configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })

})