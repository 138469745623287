import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import callAPI from '../../../commonservices/APIRequests'
import { Col, Row, Container, Image, Button } from 'react-bootstrap'
import moment from 'moment';

import CCTV from '../../../assets/Properties/amenities/cctv-camera.svg'
import Biometric from '../../../assets/Properties/amenities/biometric.svg'
import Cleaning from '../../../assets/Properties/amenities/cleaning.svg'
import Security from '../../../assets/Properties/amenities/security.svg'
import Gym from '../../../assets/Properties/amenities/dumbbell-gym.svg'
import Wifi from '../../../assets/Properties/amenities/wifi.svg'
import LaundaryIcon from '../../../assets/Properties/laundry.svg'
import './ViewProperty.css'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import AdminLayout from '../../Shared/common/AdminLayout/AdminLayout';



const ViewProperty = () => {
    const { id: selectedPropertyId } = useParams();
    const [data, setData] = useState({});
    const [showAll, setShowAll] = useState(false);
    const navigate = useNavigate()
    const [showReadMoreData, setShowReadMoreData] = useState(false)

    useEffect(() => {
        const getLinkPropertyData = (id) => {
            const req = {
                propertyId: id
            }
            callAPI.get(`/property/getPropertyById`, req)
                .then(res => {
                    if (res.status === 200) {
                        // toast.success(res.data?.message ?? '');
                        // navigate(`/property/${id}`, { state: { propertyData: res.data?.data ?? {} } })
                        setData(res.data?.data)
                    }
                })

        }
        getLinkPropertyData(selectedPropertyId)
    }, [])

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    let amenities = [
        { name: 'CCTV', icon: CCTV },
        { name: 'Wifi', icon: Wifi },
        { name: 'Cleaning', icon: Cleaning },
        { name: 'Laundary', icon: LaundaryIcon },
        { name: 'Biometric', icon: Biometric },
        { name: 'Security', icon: Security },
        { name: 'Gym', icon: Gym }
        // Add more amenities as needed
    ];

    for (let i = 0; i < data?.amenities?.length; i++) {
        const index = amenities.findIndex(amenity => amenity.name === data.amenities[i]);
        if (index > -1) {
            amenities[index].isvisible = true;
        }
    }

    const propertyWidths = [500, 200, 600, 300, 225, 175];

    let imageContent = [];

    for (let i = 0; i < data?.images?.length; i++) {
        imageContent.push({ content: data?.images[i], width: propertyWidths[i] });
    }

    return (
        <AdminLayout>
            <Container>
                <Row className='property-page-wrapper mx-0 view-property-admin-wrapper'>
                    <Col md={5} className='property_image'>
                        <swiper-container
                            navigation="true"
                            // pagination="true"
                            slides-per-view="1"
                            space-between="30"
                            centered-slides={false}
                        >
                            {imageContent.map((item, index) => <swiper-slide><img src={`${item.content}`} alt="property" /></swiper-slide>)}
                        </swiper-container>
                    </Col>
                    {
                        data && <>
                            <Col md={7} className='property_details'>
                                <Row className="mx-0 ">
                                    <Col className="left_section">
                                        <div className='left_section_content'>
                                            <h3>{data?.propertyName ?? ''}</h3>
                                            <h4>{data?.propertyLocation ?? ''}</h4>
                                            <div className='classic-content'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div>
                                                        <p className='label'>{data?.category === 'We-Cation' ? 'Per Night ' : 'Monthly Rent'}</p>
                                                        <p className='value'>
                                                            {data?.category === 'Housing' && (
                                                                <div>
                                                                    {data ? `₹${data?.monthlyRentForRoom || 'N/A'}` : 'N/A'}
                                                                </div>
                                                            )}
                                                            {data?.category === 'We-Cation' && (
                                                                <div>
                                                                    {data ? `₹${data?.price || 'N/A'}` : 'N/A'}
                                                                </div>
                                                            )}
                                                            {data?.category === 'Lifespaces' && (
                                                                <div>
                                                                    {data ? `₹${data?.monthlyRentForRoom || 'N/A'}` : 'N/A'}
                                                                </div>
                                                            )}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className='property-features'>
                                                    {data?.category !== 'We-Cation' &&
                                                        <>
                                                            <div>
                                                                <p className='label'>Security</p>
                                                                <p className='value'>{data?.securityFee ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Maintanance</p>
                                                                <p className='value'>{data?.maintananceFee ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Available from</p>
                                                                <p className='value'>{moment(data?.availableFrom).format('MMMM D, YYYY')}</p>
                                                            </div>
                                                        </>
                                                    }

                                                    {data?.category === 'Housing' &&
                                                        <>
                                                            <div>
                                                                <p className='label'>Bathrooms</p>
                                                                <p className='value'>{data?.noOfBathRooms ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Balcony</p>
                                                                <p className='value'>{data?.balcony ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Floor</p>
                                                                <p className='value'>{data?.noOfFloors ?? 'N/A'}</p>
                                                            </div>
                                                        </>}
                                                    {data?.category === 'Lifespaces' &&
                                                        <>
                                                            <div>
                                                                <p className='label'>Triple Sharing</p>
                                                                <p className='value'>{data?.tripleSharingPrice ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Shared Room</p>
                                                                <p className='value'>{data?.sharedRoomPrice ?? 'N/A'}</p>
                                                            </div>
                                                            <div>
                                                                <p className='label'>Private Room</p>
                                                                <p className='value'>{data?.privateRoomPrice ?? 'N/A'}</p>
                                                            </div>
                                                        </>}
                                                </div>
                                                <div className='black-divider'>
                                                    <span></span>
                                                </div>

                                                <div className='amenities-row'>
                                                    {amenities.map((amenity, index) => (
                                                        amenity?.isvisible ? (
                                                            <div key={index}>
                                                                <div>
                                                                    <span>
                                                                        <img src={amenity.icon} alt={amenity.name} />
                                                                    </span>
                                                                    <p>{amenity.name}</p>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </>

                    }
                </Row>

                <div className='about-property'>
                    <h4 className='mb-4'>About this property</h4>
                    <p>{data?.propertyDescription ?? ''}</p>
                </div>
            </Container>
        </AdminLayout>
    )
}

export default ViewProperty