import React from 'react';
import Slider from "react-slick";
import './Variant_SlickSlider.css';
import { Image } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function Variant_SlickSlider({ slideData }) {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    speed: 200, // Faster transition
    cssEase: "ease-out",
    nextArrow: <ChevronRight />, // Include arrows
    prevArrow: <ChevronLeft />, // Include arrows
    adaptiveHeight: true, // Adjust height based on content
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          nextArrow: <ChevronRight />, // Include arrows for this breakpoint
          prevArrow: <ChevronLeft />  // Include arrows for this breakpoint
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: false,
          nextArrow: <ChevronRight />, // Include arrows for this breakpoint
          prevArrow: <ChevronLeft />  // Include arrows for this breakpoint
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          autoplay: false,
          nextArrow: <ChevronRight />, // Include arrows for this breakpoint
          prevArrow: <ChevronLeft />  // Include arrows for this breakpoint
        }
      }
    ],
    lazyLoad: 'ondemand', // Load images only when needed
  };

  return (
    <div className='variant-slick-slider'>
      <div className="slider-container">
        <Slider {...settings}>
          {slideData.map((slide, index) => (
            <div key={index} style={{ width: slide.width }}>
              <Image src={slide.content} fluid />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Variant_SlickSlider;