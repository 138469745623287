import React, { useState } from "react"
import { Modal, Button, Form, Image } from 'react-bootstrap';
import callAPI from "../../commonservices/APIRequests";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setIsForgot } from "../Store/Shared";


const ChangePassword = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { isForgot } = useSelector(store => store.sharedReducer);
    const [validated, setValidated] = useState(false)
    const [formData, setFormData] = useState({})
    const [isMissMatch, setIsMissMatch] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setValidated(true)

        if (formData.cpassword !== formData.password) {
            setIsMissMatch(true)
        } else {
            setIsMissMatch(false)
        }

        const form = event.currentTarget;
        if (form.checkValidity() !== false) {

            callAPI.post('/users/changePassword', formData)
                .then(res => {
                    if (res.status === 200) {
                        setFormData({})
                        setValidated(false)
                        dispatch(setIsForgot(false))
                        navigate('/', { state: { activeTab: 'signIn' } })
                    } else {
                        setValidated(false)
                    }
                })
        }
    }


    const handleChange = (key, value) => {
        setFormData({ ...formData, [key]: value })
    }

    return (
        <Modal className='sign-in-popup' show={isForgot} >
            <Modal.Body className='p-0'>
                <h3 className="cursor-pointer text-center mb-4">Forgot Password</h3>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <Form.Group className="mb-1 mt-3" controlId="formBasicEmail">
                        <Form.Control
                            required
                            type="email"
                            name='email'
                            value={formData?.email ?? ''}
                            placeholder="Enter email"
                            onChange={(e) => handleChange(e.target.name, e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid Email
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-0 mt-3 position-relative password-field" controlId="formBasicPassword">
                        <Form.Control
                            required
                            type="password"
                            name='password'
                            value={formData?.password ?? ''}
                            placeholder="Password"
                            onChange={(e) => handleChange(e.target.name, e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid Password
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3 position-relative password-field" controlId="formBasicPassword">
                        <Form.Control
                            required
                            name='cpassword'
                            value={formData?.cpassword ?? ''}
                            placeholder="Confirm Password"
                            onChange={(e) => handleChange(e.target.name, e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid Confirm Password
                        </Form.Control.Feedback>
                    </Form.Group>

                    
                    {isMissMatch ? <p className="custom-error-text">Password Miss Match</p> : null}
                    <div className='text-center mt-4'>
                        <Button variant="primary" type="submit" className="cursor-pointer default-blue-bg">
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePassword
