import React, { useState } from 'react';
import { Button, Col, Container, Image, Row, Modal } from 'react-bootstrap';
import './Footer.css';
import Logo from '../../assets/footer/footer_logo.svg';
import FooterBanner from '../../assets/footer/footer_banner.png';
import PhoneIcon from '../../assets/Icons/phone_black.svg';
import MailIcon from '../../assets/Icons/mail_black.svg';
import FbIcon from '../../assets/Icons/fb_theme_color.svg';
import InstaIcon from '../../assets/Icons/insta_theme_color.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState("");
  
    const handleShow = (content) => {
      setModalContent(content);
      setShowModal(true);
    };
  
    const handleClose = () => setShowModal(false);
  
    return (
      <div className="footer-wrap">
        <footer>
          <Container>
            <Row className="mx-0 justify-content-between align-items-start">
              <Col className="flex-grow-0 contact-details">
                <Image src={Logo} className="footer-logo mb-3" />
                <div className="first-col-content">
                  <p className="address mb-3">
                    <span>WEWORK, Rajpushpa Summit, </span>
                    <br />
                    <span>1st floor,Nanakramguda Rd, </span>
                    <br />
                    <span>Financial District, Manikonda, </span>
                    <br />
                    <span>Hyderabad, Telangana 500032</span>
                  </p>
                  <div className="mail-and-contact">
                    <div className="d-flex align-items-center mb-3">
                      <Image src={PhoneIcon} className="me-2" />
                      <p>+91-94939 27132</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <Image src={MailIcon} className="me-2" />
                      <p>marketing@weliving.in</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="flex-grow-0 quick-links">
                <h5>Quick Links</h5>
                <ul>
                  <li><Link to="/about" className="nav-link">About</Link></li>
                  <li><Link to="/collections" className="nav-link">Collections & Offers</Link></li>
                  <li><Link to="/blogs" className="nav-link">Blogs</Link></li>
                </ul>
              </Col>
              <Col className="flex-grow-0 footer-banner">
                <div>
                  <Image src={FooterBanner} />
                </div>
              </Col>
              <Col className="flex-grow-0 terms-col-footer">
                <Button
                  className="black-btn-shadow"
                  onClick={() => handleShow("Terms and Conditions")}
                >
                  Terms and Conditions
                </Button>
                <Button
                  className="black-btn-shadow"
                  onClick={() => handleShow("Privacy Policy")}
                >
                  Privacy Policy
                </Button>
                <div className="d-flex align-items-center">
                  <p className="me-3">Follow us on:</p>
                  <div>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                      <Image src={FbIcon} className="me-3" alt="fb" />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                      <Image src={InstaIcon} className="me-3" alt="insta" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        <span className="footer-strip"></span>

            {/* Modal */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent === "Terms and Conditions" && (
                        <p>
                            <strong>1. Introduction:</strong> <br></br>
                            Welcome to Weliving. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing this website and booking a property with us, you accept these terms and conditions. Do not continue to use our services if you do not agree with any part of these terms.<br></br>

                            <strong>2. Booking and Payment:</strong> <br></br>
                            All bookings are subject to availability and must be made through our website or by contacting our team directly.
                            Payment for bookings must be made in full prior to your stay unless otherwise agreed upon.
                            We accept various payment methods, including credit/debit cards, bank transfers, and online payment gateways.<br></br>

                            <strong>3. Cancellations and Refunds:</strong> <br></br>
                            Cancellations made within [specified time] of the booking date may be eligible for a partial or full refund, depending on the property's cancellation policy.
                            No-shows or late cancellations may result in the forfeiture of payment.
                            Refunds will be processed within [specified time frame] of cancellation.<br></br>

                            <strong>4. Use of Properties: </strong><br></br>
                            Guests and tenants must respect the property and its amenities, following any house rules provided.
                            Smoking and pets may be restricted in certain properties; please review the property's policies.
                            Damages caused to the property may result in additional charges.<br></br>

                            <strong>5. Liability:</strong> <br></br>
                            Weliving is not liable for any loss, damage, or injury during your stay at our properties.
                            We recommend obtaining travel insurance for protection against unforeseen circumstances.<br></br>

                            <strong>6. Privacy:</strong> <br></br>
                            We value your privacy and handle your personal information in accordance with our privacy policy.
                            By using our services, you consent to the collection, use, and disclosure of your information as described in our privacy policy.<br></br>

                            <strong>7. Dispute Resolution:</strong> <br></br>
                            Any disputes arising from the use of our services shall be resolved through negotiation. If a resolution cannot be reached, the dispute may be referred to mediation or arbitration.<br></br>
                            <strong>8. Governing Law: </strong>8<br></br>
                            These terms and conditions are governed by the laws of [jurisdiction], and any legal action must be brought in a court of competent jurisdiction in [location].<br></br>
                            <strong>9. Changes to Terms and Conditions: </strong><br></br>
                            We reserve the right to modify these terms and conditions at any time. Changes will take effect immediately upon posting on our website.<br></br>

                           </p>
                    )}
                    {modalContent === "Privacy Policy" && (
                        <p> <strong>1. Introduction:</strong> <br></br>
                        This privacy policy explains how Weliving collects, uses, and protects your personal information. By using our website and services, you consent to the data practices described in this policy.<br></br>
                        <strong>2. Information We Collect: </strong><br></br>
                        Personal Information: Name, email address, phone number, payment information, and other data provided during bookings and interactions.
                        Usage Information: Data about how you use our website, such as pages visited, interactions, and browsing behavior.<br></br>
                        <strong>3. How We Use Your Information:</strong> <br></br>
                        Service Delivery: To facilitate bookings, manage reservations, and provide customer support.
                        Marketing: To send promotional offers, newsletters, and updates (with your consent).
                        Improvements: To analyze user behavior and improve our website and services.<br></br>
                        <strong>4. Sharing Your Information:</strong> <br></br>
                        We may share your information with trusted third-party service providers for the purpose of delivering our services.
                        We will not sell, rent, or lease your personal information to third parties without your explicit consent.<br></br>
                        <strong>5. Data Security:</strong> <br></br>
                        We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure.
                        However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.<br></br>
                        <strong>6. Your Rights:</strong> <br></br>
                        You have the right to access, correct, or delete your personal information in our possession.
                        You can opt out of receiving marketing communications at any time.<br></br>
                        <strong>7. Cookies and Tracking Technologies:</strong> <br></br>
                        We use cookies and other tracking technologies to enhance your experience on our website.
                        You can manage your cookie preferences in your browser settings.<br></br>
                        <strong>8. Changes to Privacy Policy:</strong> <br></br>
                        We reserve the right to update this privacy policy as needed. Any changes will be posted on our website. <br></br>
                        <strong>9. Contact Us: </strong><br></br>
                        If you have any questions or concerns about this privacy policy, please contact us at [contact information].
                        
                            </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Footer;
