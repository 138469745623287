import React, { useState, useEffect } from 'react';
import callAPI from '../../../commonservices/APIRequests';
import { Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';
import ReactTable from '../../ReactTable/ReactTable';
import { convertToBaseFormatDate } from '../../../commonservices/commonMethods';
import { useLocation } from 'react-router-dom';
import Header from '../../Header/Header';
import NewAdminHeader from '../../NewAdminPortal/NewAdminHeader/NewAdminHeader';

const Booking = (props) => {
    const [bookingData, setBookingData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // Filtered data for the table
    const [searchQuery, setSearchQuery] = useState(''); // Search query
    const [data, setData] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [newBooking, setNewBooking] = useState({
        propertyName: '',
        checkIn: '',
        checkOut: '',
        paymentStatus: 'Pending',
    });
    const location = useLocation();
    const propertyType = [
        { label: 'We-spaces', value: 'Lifespaces' },
        { label: 'We-cation', value: 'We-Cation' },
        { label: 'We-living', value: 'Housing' },
    ];
    const useLogins = JSON.parse(localStorage.getItem('loginData'));

    useEffect(() => {
        getBooking(location.state?.tabName ?? '');
    }, [location?.state?.tabName]);

    useEffect(() => {
        const dataColumns = [
            {
                Header: 'Property Id',
                accessor: (row) => row.propertyId?._id ?? '',
                filter: 'contains',
                Cell: (props) => <p>{props.value}</p>,
            },
            {
                Header: 'Property Name',
                accessor: (row) => row.propertyId?.propertyName ?? '',
                filter: 'contains',
                Cell: (props) => <p>{props.value}</p>,
            },
            {
                Header: 'Check-In',
                accessor: 'bookingStartDate',
                filter: 'contains',
                Cell: (props) => <p>{convertToBaseFormatDate(props.value)}</p>,
            },
            {
                Header: 'Check-Out',
                accessor: 'bookingEndDate',
                filter: 'contains',
                Cell: (props) => <p>{convertToBaseFormatDate(props.value)}</p>,
            },
            {
                Header: 'Status',
                accessor: 'paymentStatus',
                filter: 'contains',
                Cell: (props) => <p>{props.value}</p>,
            },
        ];
        setColumns(dataColumns);
    }, [bookingData]);

    useEffect(() => {
        // Filter bookingData based on search query
        if (searchQuery.trim() === '') {
            setFilteredData(bookingData);
        } else {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = bookingData.filter((item) => {
                return (
                    Object.values(item || {}) // Ensure `item` is not null/undefined
                        .flatMap((value) =>
                            typeof value === 'object'
                                ? Object.values(value || {}) // Ensure nested objects are safe
                                : value
                        )
                        .some((value) =>
                            (value ? value.toString() : '') // Handle null/undefined values
                                .toLowerCase()
                                .includes(lowerCaseQuery)
                        )
                );
            });
            setFilteredData(filtered);
        }
    }, [searchQuery, bookingData]);
    
    const toggleModal = () => setShowModal(!showModal);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBooking({ ...newBooking, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        callAPI
            .post('/property/addBooking', newBooking)
            .then((res) => {
                if (res.status === 200) {
                    alert('Booking added successfully!');
                    setBookingData([...bookingData, res.data]);
                    toggleModal();
                } else {
                    alert('Failed to add booking.');
                }
            })
            .catch((err) => {
                console.error('Error adding booking:', err);
            });
    };

    const getBooking = (type) => {
        const req = {
            type: type === 'Requests' ? 'Requested_For_Tour' : 'Cart',
        };
        if (useLogins.role !== 'Admin' && location.state?.isUser) {
            req.userId = useLogins?._id ?? '';
        }
        callAPI
            .get('/property/getUserProperties', req)
            .then((res) => {
                if (res.status === 200) {
                    setBookingData(res.data?.data ?? []);
                } else {
                    setBookingData([]);
                }
            });
    };

    const handleChange = (value) => {
        setData(value);
    };

    return (
        <>
            <div className="admin-content-wrapper">
                <NewAdminHeader />
                {useLogins.role !== 'Admin' && location.state?.isUser && <Header />}
                <div className="admin-tab-content-box booking-admin-wrapper">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3>Bookings</h3>
                        <Button variant="primary" onClick={toggleModal}>
                            Add New Booking
                        </Button>
                    </div>
                    <div className="mb-3 d-flex justify-content-between align-items-center">
  <Select 
    isClearable={false} 
    isSearchable 
    name="Select Type" 
    placeholder="Select..." 
    className="react-select-dealerlist" 
    options={propertyType} 
    value={propertyType?.find((item) => item.value === data) ?? null} 
    onChange={(val) => handleChange(val.value)} 
    menuPlacement="auto" 
  />
  <Form.Control 
    type="text" 
    placeholder="Search " 
    value={searchQuery} 
    onChange={(e) => setSearchQuery(e.target.value)} 
    style={{ width: '160px', height: '40px' }}
    
  />
</div>
                    {/* <div className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Search Property ID, Name, Check-In, etc..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div> */}
                    {/* <div className="mb-3 d-flex justify-content-end">
  <Form.Control 
    type="text" 
    placeholder="Search" 
    value={searchQuery} 
    onChange={(e) => setSearchQuery(e.target.value)}
    style={{ width: '250px' }} 
  />
</div>
                    <Select
                        isClearable={false}
                        isSearchable
                        name="Select Type"
                        placeholder="Select..."
                        className="react-select-dealerlist"
                        options={propertyType}
                        value={propertyType?.find((item) => item.value === data) ?? null}
                        onChange={(val) => handleChange(val.value)}
                        menuPlacement="auto"
                    /> */}
                    <div className="mt-5 ml-2">
                        <ReactTable columns={columns} data={filteredData} />
                    </div>
                </div>
            </div>

            {/* Modal for Adding New Booking */}
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group>
                            <Form.Label>Property Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="propertyName"
                                value={newBooking.propertyName}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Check-In Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="checkIn"
                                value={newBooking.checkIn}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Check-Out Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="checkOut"
                                value={newBooking.checkOut}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Payment Status</Form.Label>
                            <Form.Control
                                type="text"
                                name="paymentStatus"
                                value={newBooking.paymentStatus}
                                onChange={handleInputChange}
                                disabled
                            />
                        </Form.Group>
                        <Button type="submit" variant="success">
                            Submit
                        </Button>
                        <Button variant="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Booking;
