import { createSlice, current } from "@reduxjs/toolkit";

const defaultState = {
    isLogin: false,
    userData: localStorage.getItem('loginData') ? JSON.parse(localStorage.getItem('loginData')) : {},
    isForgot: false
}
const sharedReducer = createSlice({
    name: "Shared",
    initialState: defaultState,
    reducers: {
        userLogin: (state, action) => {
            state = {
                ...state,
                isLogin: action.payload,
            }
            return state
        },
        userData: (state, action) => {
            state = {
                ...state,
                userData: action.payload,
            }
            return state
        },
        setIsForgot: (state, action) => {
            state = {
                ...state,
                isForgot: action.payload,
            }
            return state
        }
    }
})

export const { userLogin, userData, setIsForgot } = sharedReducer.actions;

export default sharedReducer.reducer;

