
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Header from "../Header/Header";
import { Container, Form } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import callAPI from '../../commonservices/APIRequests';
import { toast } from 'react-toastify';


const Profile = (props) => {
  const { userData } = useSelector(store => store.sharedReducer);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const [editedUserData, setEditedUserData] = useState(userData);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    callAPI.post('/users/updateUser', editedUserData)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Profile updated successfully');
          //dispatch(userData(editedUserData));
        } else {
          toast.error('Error while updating profile');
        }
      })
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUserData({ user_id: userData._id, [name]: value });
  };

  return (
    <div className="profile-page-wrapper">
      <Header />
      <Container>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {/* Nav items can be added here if needed */}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Form noValidate>
                    <Form.Group as={Row}>
                      <Form.Group as={Col} md={6} controlId="formBasicEmail">
                        <Form.Label className="mb-0">Name</Form.Label>
                        <Form.Control
                          type="text"
                          name='name'
                          value={isEditing ? editedUserData.name : userData?.name}
                          placeholder="Enter First Name"
                          onChange={handleInputChange}
                          readOnly={!isEditing}
                          disabled={!isEditing}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={6} controlId="formBasicPhone">
                        <Form.Label className="mb-0">Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          name='phoneNumber'
                          value={isEditing ? editedUserData.phoneNumber : userData?.phoneNumber}
                          placeholder="Enter Phone Number"
                          onChange={handleInputChange}
                          readOnly={!isEditing}
                          disabled={!isEditing}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={6} controlId="formBasicEmail">
                        <Form.Label className="mb-0">Email</Form.Label>
                        <Form.Control
                          type="email"
                          name='email'
                          value={isEditing ? editedUserData.email : userData?.email}
                          placeholder="Enter Email"
                          onChange={handleInputChange}
                          readOnly={!isEditing}
                          disabled={!isEditing}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={6} controlId="formBasicRole">
                        <Form.Label className="mb-0">Role</Form.Label>
                        <Form.Control
                          type="text"
                          name='role'
                          value={isEditing ? editedUserData.role : userData?.role}
                          placeholder="Enter role"
                          onChange={handleInputChange}
                          readOnly={true}
                          disabled={true}
                        />
                      </Form.Group>
                    </Form.Group>
                    {isEditing ? (
                      <Button
                        variant="primary"
                        onClick={handleSave}
                        style={{
                          float: 'right',
                          marginTop: '10px',
                        }}
                      >
                        Save Changes
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={handleEdit}
                        style={{
                          float: 'right',
                          marginTop: '10px',
                        }}
                      >
                        Edit Profile
                      </Button>
                    )}
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Profile;