import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Image, Nav } from 'react-bootstrap';
import './SideNavBar.css';
import Logo from '../../../assets/SideNavBar/logo.png';
import BookingsIcon from '../../../assets/SideNavBar/bookings.svg';
import ContentIcon from '../../../assets/SideNavBar/content.svg';
import RequestIcon from '../../../assets/SideNavBar/request.svg';
import UsersIcon from '../../../assets/SideNavBar/users.svg'; // New icon for Users
import PropertyInActiveIcon from '../../../assets/SideNavBar/propertiesInactive.svg';
import { useNavigate } from 'react-router-dom';

const SideNavBar = () => {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const handleLogoClick = () => {
        setIsActive(!isActive);
        navigate('/');
    };

    const handleNavLinkClick = (tabName) => {
        navigate('/newAdminPortal', { state: { tabName, isUser: false } });
    };
    
        
    
       

    return (
        <div className="side-nav-bar-wrapper d-flex flex-column vh-100 p-3" style={{ width: '250px' }}>
        <div className="mb-3 header-logo">
            <img 
                src={Logo} 
                alt="Logo" 
                className={`me-2 ${isActive ? 'active' : ''}`} 
                onClick={handleLogoClick} 
            />
        </div>
    
            <Nav className="flex-column">
                 {/* New Users Navigation Item */}
                 <div onClick={() => handleNavLinkClick('Users')} className="nav-link" role="button">
                    <Image src={UsersIcon} alt={'users'} /> Users
                </div>
                <div
                    onClick={() => handleNavLinkClick('Properties')}
                    className="nav-link"
                    role="button"
                >
                    <Image src={PropertyInActiveIcon} alt={'properties'} /> Properties
                </div>

                <Nav className="flex-column properties-sub-menu ms-3">
                    <div
                        onClick={() => handleNavLinkClick('Weliving')}
                        className="nav-link"
                        role="button"
                    >
                        We-living
                    </div>
                    <div
                        onClick={() => handleNavLinkClick('Welifespaces')}
                        className="nav-link"
                        role="button"
                    >
                        We-spaces
                    </div>
                    <div
                        onClick={() => handleNavLinkClick('Wecation')}
                        className="nav-link"
                        role="button"
                    >
                        We-cation
                    </div>
                </Nav>

                <div
                    onClick={() => handleNavLinkClick('Bookings')}
                    className="nav-link"
                    role="button"
                >
                    <Image src={BookingsIcon} alt={'bookings'} /> Bookings
                </div>

                <NavLink
                    to={{ pathname: "/content", state: { tabName: 'Content' } }}
                    className="nav-link"
                >
                    <Image src={ContentIcon} alt={'content'} /> Content
                </NavLink>
                
                {/* <div
                    onClick={() => handleNavLinkClick('Requests')}
                    className="nav-link"
                    role="button"
                >
                    <Image src={RequestIcon} alt={'requests'} /> Requests
                </div> */}

               
            </Nav>
        </div>
    );
};

export default SideNavBar;