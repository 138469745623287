import React from 'react'
import './Collections.css'
import Header from '../Header/Header'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import asideImg1 from '../../assets/SearchResults/aside-img-1.png'
import asideImg2 from '../../assets/SearchResults/aside-img-2.png'
import OfferImg from '../../assets/Collections/Offers/offer_1.png'
import { useNavigate } from 'react-router-dom'
import NavSlider from '../Shared/common/NavSlider/NavSlider'
import CollectionsBannerImg from "../../assets/Collections/collection-bg-banner.png"
import callAPI from '../../commonservices/APIRequests'


const Collections = () => {
    const navigate = useNavigate()

    return (
        <div className='collections-page-wrapper default-aside-col'>
            <Header />
            <div>

                <div className='collection-page-content-wrapper'>
                    <div className='bg-collection-image'>
                        <Image src={CollectionsBannerImg} alt='banner' />
                    </div>
                    <div className='nav-slider-collection'>
                        <NavSlider />
                    </div>

                </div>
                <Container>
                    <Row className='mx-0 d-none'>
                        <Col className='ps-0 padding-zero'>
                            <div>
                                <Row className='mx-0'>
                                    <h2 className='section-title'>Collections</h2>
                                    <p className='section-sub-title'>At WeLiving, we curate collections of properties tailored to your unique travel needs. Discover the perfect stay for your next adventure!</p>
                                    <Row className='mx-0 cards-group'>
                                        <Col md={6} onClick={() => navigate('/eachcollections/bachelors')} className='cursor-pointer collection-cards ps-0'>
                                            <div className='title'>Bachelor Friendly</div>
                                            
                                        </Col>
                                        <Col md={6} className='cursor-pointer collection-cards pe-0' onClick={() => navigate('/eachcollections/business')}>
                                            <div className='title'>Business Stays</div>
                                        </Col>
                                        <Col md={6} className='cursor-pointer collection-cards ps-0' onClick={() => navigate('/eachcollections/family')}>
                                            <div className='title'>Family Homes</div>
                                        </Col>
                                        <Col md={6} className='cursor-pointer collection-cards pe-0' onClick={() => navigate('/eachcollections/couples')}>
                                            <div className='title'>Student Homes</div>
                                        </Col>
                                    </Row>
                                </Row>
                                <Row className='offers-cards mx-0'>
                                    <h2 className='section-title'>Offers</h2>
                                    <Row className='mx-0 cards-group'>
                                        <Col md={6} className='ps-0 collection-cards'>
                                            <div><Image src={OfferImg} />
                                                <Button className='check-property-btn'>Check Property</Button>
                                            </div>
                                        </Col>
                                        <Col md={6} className='pe-0 collection-cards'>
                                            <div><Image src={OfferImg} />
                                                <Button className='check-property-btn'>Check Property</Button>
                                            </div>
                                        </Col>
                                        <Col md={6} className='ps-0 collection-cards'>
                                            <div><Image src={OfferImg} />
                                                <Button className='check-property-btn'>Check Property</Button>
                                            </div>
                                        </Col>
                                        <Col md={6} className='pe-0 collection-cards'>
                                            <div><Image src={OfferImg} />
                                                <Button className='check-property-btn'>Check Property</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Row>
                            </div>
                        </Col>
                        <Col className='aside-column pe-0'>
                            <div className='aside-img'>
                                <Image src={asideImg1} alt='aside image' />
                                <div className="d-flex justify-content-center">
                                    <Button className='black-btn-shadow'>explore</Button>
                                </div>
                            </div>
                            <div className='aside-img'>
                                <Image src={asideImg2} alt='aside image' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Collections