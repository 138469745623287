import React from "react"
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import '../VerifyOtp/verifyOtp.css'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import callAPI from "../../commonservices/APIRequests";

const VerifyOtp = (props) => {
  const [validate, setValidate] = useState(false)
  const [otpData, setOtpData] = useState({ value: '', otp1: "", otp2: "", otp3: "", otp4: "", disable: true })
  const navigation = useNavigate()

  const loginData = JSON.parse(localStorage.getItem('loginData'))
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidate(true)

    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      const req = {
        email: loginData?.email ?? '',
        otpNumber: otpData.otp1 + otpData.otp2 + otpData.otp3 + otpData.otp4
      }
      callAPI.post('/users/verifyLoginOtp', req)
        .then(res => {
          if (res.status === 200) {
            props.setIsLogin(true)
            props.setVerifyOtpShow(false)
            setValidate(false)
            localStorage.setItem('token', res.data.token);
            toast.success("OTP Verified Successfully");
            navigation('/')

          } else {
            setValidate(false)
            toast.error('Invalid OTP');
          }
        })

    }
  }

  const handleChange = (key, value) => {

    setOtpData({
      ...otpData,
      [key]: value
    })

  }

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target.form.elements[next].focus()
      }
    }

  }

  return (
    <>
      <div className="verify-OTP">
        {/* <Image src={logo} className="login-logo" fluid /> */}
        <h2 className="text-center bold-500 brandName mt-4">Authentication Required</h2>
        <p>Please enter the OTP code sent on your email</p>
        <Form
          noValidate
          validated={validate}
          onSubmit={(event) => handleSubmit(event)}>
          <div className="feilds my-3">
            <Form.Group className="d-flex justify-content-center otp-fields-row">
              <Form.Control
                required
                type="txt"
                className="otp"
                aria-describedby="passwordHelpBlock"
                tabIndex='1'
                maxLength='1'
                onChange={(event) => handleChange('otp1', event.target.value)}
                onKeyUp={e => inputfocus(e)}
              />
              <Form.Control
                required
                type="txt"
                aria-describedby="passwordHelpBlock"
                tabIndex='2'
                className="otp"
                maxLength='1'
                onChange={(event) => handleChange('otp2', event.target.value)}
                onKeyUp={e => inputfocus(e)}
              />
              <Form.Control
                required
                type="txt"
                aria-describedby="passwordHelpBlock"
                tabIndex='3'
                className="otp"
                maxLength='1'
                onChange={(event) => handleChange('otp3', event.target.value)}
                onKeyUp={e => inputfocus(e)}
              />
              <Form.Control
                required
                type="txt"
                aria-describedby="passwordHelpBlock"
                tabIndex='4'
                className="otp"
                maxLength='1'
                onChange={(event) => handleChange('otp4', event.target.value)}
                onKeyUp={e => inputfocus(e)}
              />
            </Form.Group>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Button type="submit" className="verify-btn">Verify OTP</Button></div>
        </Form>
      </div>
    </>
  )
}

export default VerifyOtp;
