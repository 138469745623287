import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { EllipsisVertical, Building, CalendarDays } from 'lucide-react'
import AdminHeader from '../AdminHeader/AdminHeader';
import NewAdminHeader from '../../NewAdminPortal/NewAdminHeader/NewAdminHeader';

const DashboardTab = () => {
    return (
        <div className='admin-content-wrapper admin-dashboard-wrapper'>

            {/* <AdminHeader /> */}
            <NewAdminHeader />
            <div className='admin-tab-content-box'>
                <div className='admin-breadcrumb-header d-flex justify-content-between align-items-end'>
                    <div>
                        <h2>Dashboard</h2>
                        <p>Home / Dashboard</p>
                    </div>
                    <div>
                        <CalendarDays className='me-2' />
                        <span>Oct 11,2023 - Nov 11,2022</span>
                    </div>
                </div>
                <div className='top-widget-admin-dashboard'>
                    <div className='top-widget-card'>
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            <h3 className='mb-0'>Total Revenue</h3>
                            <span><EllipsisVertical /> </span>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                            <span className='me-2'><Building /></span>
                            <h3 className='mb-0'>&#8377; 126.500</h3>
                        </div>
                        <div>
                            <span>Compared to Oct 2023</span>
                        </div>
                    </div>
                    <div className='top-widget-card'>
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            <h3 className='mb-0'>Total Revenue</h3>
                            <span><EllipsisVertical /> </span>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                            <span className='me-2'><Building /></span>
                            <h3 className='mb-0'>&#8377; 126.500</h3>
                        </div>
                        <div>
                            <span>Compared to Oct 2023</span>
                        </div>
                    </div>
                    <div className='top-widget-card'>
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            <h3 className='mb-0'>Total Revenue</h3>
                            <span><EllipsisVertical /> </span>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                            <span className='me-2'><Building /></span>
                            <h3 className='mb-0'>&#8377; 126.500</h3>
                        </div>
                        <div>
                            <span>Compared to Oct 2023</span>
                        </div>
                    </div>
                    <div className='top-widget-card'>
                        <div className='d-flex mb-2 align-items-center justify-content-between'>
                            <h3 className='mb-0'>Total Revenue</h3>
                            <span><EllipsisVertical /> </span>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                            <span className='me-2'><Building /></span>
                            <h3 className='mb-0'>&#8377; 126.500</h3>
                        </div>
                        <div>
                            <span>Compared to Oct 2023</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardTab