import React, { useEffect, useState } from 'react'
import SideNavBar from './SideNavBar/SideNavBar'
import { useLocation } from 'react-router-dom'
import AdminPropertyView from '../AdminPortal/AdminPropertyView/AdminPropertyView'
import Booking from '../AdminPortal/Booking/Booking'
import Dashboard from '../AdminPortal/Dashboard/Dashboard'

const NewAdminPortal = () => {
    const location = useLocation()
    const [activeComponent, setActiveComponent] = useState(<Dashboard />);
    const useLogins = JSON.parse(localStorage.getItem('loginData'))

    useEffect(() => {
        switch (location?.state?.tabName) {
            case 'Weliving':
            case 'Welifespaces':
            case 'Wecation':
            case 'Properties':
                setActiveComponent(<AdminPropertyView tabName={location?.state?.tabName} />)
                break
            case 'Bookings':
            case 'Requests':
                setActiveComponent(<Booking tabName={location?.state?.tabName} isuser={location?.state?.isUser ? true : false} />)
                break
            // case 'Dashboard':
            //     setActiveComponent(<Dashboard tabName={location?.state?.tabName} />)
            //     break
            default:
                setActiveComponent(<Dashboard />)
                break
        }

    }, [location?.state])
    return (
        <div>

            <div className='d-flex'>
                {useLogins?.role !== 'Admin' && location?.state?.isUser ? null : <SideNavBar />}
                <div className='flex-grow-1'>
                    <div>
                        {activeComponent}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewAdminPortal