import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Header from '../../Header/Header';
import BannerOne from '../../../assets/Blogs/blog-details-banner.png';
import BannerTwo from '../../../assets/Blogs/blog-details-bannerOne.png';
import { useParams } from 'react-router-dom';
import './BlogDetails.css'

const BlogDetails = () => {

    const articleId = useParams();
    // Sample data for demonstration (can be replaced with actual fetching logic)
    const [article, setArticle] = useState(null);

    // Assuming a static list of articles or fetching logic using useEffect
    useEffect(() => {
        // Replace with actual fetching logic based on articleId
        const fetchArticle = () => {
            // Simulating fetching data based on articleId
            const articles = [
                {
                    id: 1,
                    title: 'Exploring the Cultural Diversity of Neighborhoods',
                    content: 'Discover diverse neighborhoods to find the perfect community match for your lifestyle needs. Explore cultural richness today! From vibrant street markets and ethnic cuisine to historical landmarks and local festivals, immerse yourself in the cultural tapestry of urban neighborhoods. Uncover hidden gems and unique traditions that define each community, fostering a sense of belonging and appreciation for cultural diversity.',
                    date: 'Mar 30, 2024',
                    readTime: '6 min read'
                },
                {
                    id: 2,
                    title: 'The Rise of Tech in Urban Areas',
                    content: 'Witness the transformative impact of technology on urban landscapes and city living. From smart cities revolutionizing infrastructure to AI-driven innovations enhancing urban mobility, delve into the forefront of urban tech advancements. Discover how cities harness technology to address challenges like sustainability, transportation, and public services, paving the way for a smarter, more connected urban future.',
                    date: 'Apr 15, 2024',
                    readTime: '5 min read'
                },
                {
                    id: 3,
                    title: 'Sustainable Living in the City',
                    content: 'Embrace sustainable practices to lead a greener lifestyle in bustling urban environments. Learn practical tips for reducing your carbon footprint and promoting eco-friendly habits in daily life. Explore urban gardening initiatives, renewable energy solutions, and community-driven sustainability projects making a positive impact on city ecosystems. Join the movement towards sustainable living and contribute to building environmentally conscious cities.',
                    date: 'May 10, 2024',
                    readTime: '4 min read'
                },
                {
                    id: 4,
                    title: 'The Rise of Co-Living Spaces',
                    content: 'Experience the evolution of urban living with the rise of co-living spaces. Explore how these innovative housing models redefine community living, offering shared amenities and social opportunities. Discover the benefits of flexible living arrangements that cater to diverse lifestyles, from young professionals seeking collaboration to digital nomads embracing remote work. Dive into the social dynamics and affordability factors driving the popularity of co-living spaces in modern urban landscapes.',
                    date: 'Jun 20, 2024',
                    readTime: '5 min read'
                }
            ];
            

            // Find the article with matching articleId
            // const selectedArticle = articles.find(item => item.id === parseInt(articleId));
            const selectedArticle = articles.find(item => item.id === parseInt(articleId.id));
            setArticle(selectedArticle);
        };

        fetchArticle();
    }, [articleId?.id]);

    if (!article) {
        return null; // Or loading indicator if fetching from API
    }

    return (
        <div className="blog-details-wrapper">
            <Header />
            <Container>
                <Row className='justify-content-center'>
                    <Col md={8}>
                        <p className='property-path page-title-path mb-4'>Home/Articles/{article.title}</p>
                        <div className='text-center img-one'>
                            <Image src={BannerOne} alt='banner-1' className='w-100' />
                        </div>

                        <div className='blog-description'>
                            <h2>{article.title}</h2>
                            <p>{article.content}</p>
                        </div>

                        <div className='text-center img-one'>
                            <Image src={BannerTwo} alt='banner-1' className='w-100' />
                        </div>

                        <div className='blog-description'>
                                <p>
                                    Moreover, the affordability factor of co-living makes it an attractive option for young professionals, students, and remote workers who may face challenges in securing traditional housing options in expensive city centers.<br/><br />

                                    With its innovative approach to urban living, co-living represents a new era in housing where community, flexibility, and affordability converge to create dynamic living experiences that resonate with the modern urban lifestyle.
                                </p>
                            </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BlogDetails;
