import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import './CustomReactDatePicker.css'
import { Button } from 'react-bootstrap';

function CustomReactDatePicker(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(dates);
    }
  };

  const clearDate = () => {
    const today = new Date();
    setStartDate(today);
    setEndDate(today)
  }

  const applyDate = () => {
    props.handleChange({ startDate, endDate })
  }

  return (
    <div className='custom-date-picker'>
      <DatePicker
        selectsRange={props.selectsRange ? true : false}
        startDate={startDate}
        endDate={endDate}
        minDate={props.minDate ? new Date() : null}
        onChange={onChange}
        inline
      />
      <div className='date-picker-actions d-flex align-items-center'>
        <Button variant='secondary' onClick={() => clearDate()}>Clear</Button>
        <Button variant='primary' className='ms-2' onClick={() => {
          applyDate();
          props?.handleScroll();
        }}>Apply</Button>
      </div>
    </div>
  );
}

export default CustomReactDatePicker;
