import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Image, Container } from 'react-bootstrap';
import ProfileImg from '../../assets/Header/user-icon.svg'
import './Header.css'
import Logo from '../../assets/logo.svg'
import Login from '../Login/Login';
import { useNavigate } from 'react-router-dom';
import callAPI from '../../commonservices/APIRequests';
import ProfileAvatar from '../../assets/Header/avatarProfile.svg';
import ProfileSetting from '../../assets/Header/settings-profile.svg';
import ProfileBookings from '../../assets/Header/bookings-profile.svg';
import ProfileHelp from '../../assets/Header/help-profile.svg';
import ProfileSignOut from '../../assets/Header/sign_out-profile.svg';
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useDispatch } from 'react-redux';
import { userData, userLogin } from '../Store/Shared';

const Header = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [isLogin, setIsLogin] = useState(false)
    const [expanded, setExpanded] = useState(false);
    const [activeTab, setActiveTab] = useState('signIn')
    const useLogins = JSON.parse(localStorage.getItem('loginData'))

    const SignOut = () => {
        callAPI.post('/users/logout', { userId: useLogins?._id ?? '' })
            .then(res => {
                if (res.status === 200) {
                    setIsLogin(false)
                    localStorage.clear()
                    dispatch(userData({}))
                    navigate('/')
                }
            })
    }

    const propertyChange = (event, type) => {
        event.preventDefault();
        const req = {
            category: type
        }
        callAPI.get('/property/getProperties', req)
            .then(res => {
                if (res.status === 200) {
                    let data = (res.data?.data?.length > 0) ? res.data.data : []
                    let actionType = (type === 'Lifespaces') ? 'We-spaces' : (type === 'We-Cation') ? 'We-cation' : 'We-living'
                    navigate('/searchresults', { state: { searchingData: data, actionType: actionType, isSearched: false } })
                }
            })
    }

    return (
        <>
            <div>
                <div className="wrapper">
                    <div className="header-banner-wrapper">
                        <header>
                            <div className="top-strip"></div>
                            <Container>
                                <Navbar expanded={expanded} expand="lg" className='px-0'>
                                    {/* <Navbar.Brand onClick={() => navigate('/')}><Image src={Logo} alt="logo" /></Navbar.Brand> */}
                                    {/* <Navbar.Brand onClick={() => navigate('/')}>
                                    <div className="logo-background-effect">
                                    <Image src={Logo} alt="logo" />
                                    </div>
                                    </Navbar.Brand> */}
                                    <Navbar.Brand 
    onClick={() => navigate('/')} 
    className="logo-hover-effect"
>
    <Image src={Logo} alt="logo" />
</Navbar.Brand>


                                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}>

                                    </Navbar.Toggle>
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="mr-auto flex-grow-1 align-items-center">
                                            {/* <Nav.Link onClick={() => navigate('/')}>Home</Nav.Link> */}
                                            <Nav.Link onClick={() => navigate('/about')}>About</Nav.Link>
                                            {/* <Nav.Link onClick={() => navigate('/property')}>Properties</Nav.Link> */}

                                            <Nav.Link onClick={() => navigate('/collections')}>Collections & Offers</Nav.Link>
                                            <Nav.Link onClick={() => navigate('/blogs')}>Blogs</Nav.Link>

                                            <NavDropdown className='properties-dropdown' title={<span>
                                                <span>Properties</span>
                                                <span><ChevronDown className='inactive' /> <ChevronUp className='active' /></span>
                                            </span>} id="basic-nav-dropdown">
                                                <NavDropdown.Item onClick={(event) => propertyChange(event, 'Lifespaces')}>We-spaces</NavDropdown.Item>
                                                <NavDropdown.Item onClick={(event) => propertyChange(event, 'We-Cation')}>We-cation</NavDropdown.Item>
                                                <NavDropdown.Item onClick={(event) => propertyChange(event, 'Housing')}>We-living</NavDropdown.Item>
                                            </NavDropdown>

                                            {useLogins?.role === 'Admin' ? <Nav.Link onClick={() => navigate('/newAdminPortal', { state: { tabName: 'Properties', isUser: false } })}>Admin DashBoard</Nav.Link> : null}

                                            {!isLogin && Object.keys(useLogins || {}).length === 0 ? <div className='flex-grow-1 text-end profile-dropdown'>
                                                <Nav.Link onClick={() => { dispatch(userLogin(true)); setActiveTab('signIn') }}>Sign In</Nav.Link>
                                                <Nav.Link onClick={() => { dispatch(userLogin(true)); setActiveTab('signUp') }}>Sign Up</Nav.Link>
                                            </div>
                                                : <div className='flex-grow-1 text-end after-login-dropdown profile-dropdown'>
                                                    <NavDropdown title={<span className="d-flex align-items-center"><span className='flex-grow-1 text-start'><Image src={ProfileAvatar} alt="user_img" /> {useLogins?.name ?? ''}</span> <ChevronDown className='inactive' /> <ChevronUp className='active' /></span>} id="basic-nav-dropdown">
                                                        <NavDropdown.Item className='profile-drop-header'>
                                                            <span>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='profile-img-in-drop'><Image src={ProfileAvatar} className='h-100 w-100' fluid alt="user_img" /></div>
                                                                    <div>
                                                                        <p className='user-name'>{useLogins?.name ?? ''}</p>
                                                                        <span className='user-email'>{useLogins?.email ?? ''}</span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item onClick={() => navigate('/profile')}><span><Image src={ProfileSetting} alt="user_img" /> Profile Settings</span></NavDropdown.Item>
                                                        <NavDropdown.Item onClick={() => navigate('/newAdminPortal', { state: { tabName: 'Bookings', isUser: true } })}><span><Image src={ProfileBookings} alt="user_img" /> Bookings</span></NavDropdown.Item>
                                                        {/* <NavDropdown.Item onClick={() => SignOut()}><span><Image src={ProfileHelp} alt="user_img" /> Help Center</span></NavDropdown.Item> */}
                                                        <NavDropdown.Item onClick={() => SignOut()}><span><Image src={ProfileSignOut} alt="user_img" /> Sign Out</span></NavDropdown.Item>
                                                    </NavDropdown>
                                                </div>}
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </Container>
                        </header>
                    </div>
                </div>
            </div>
            <Login setIsLogin={setIsLogin} activeTab={activeTab} setActiveTab={setActiveTab} />
        </>
    )
}

export default Header

