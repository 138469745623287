import axios from 'axios';

const apiURL = window.location.origin.indexOf('localhost') > -1 ? 'http://localhost:3002/api/v1' : `${window.location.origin}/node/api/v1`;

const axiosApiInstance = axios.create({ baseURL: apiURL });

export const interceptor = () => {

    axiosApiInstance.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        if (token) {

            config.headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }

        }
        else {
            config.headers = {
                'Accept': 'application/json'

            }
        }
        return config;
    },
        error => {
            Promise.reject(error)
        });

    // Response interceptor for API calls
    axiosApiInstance.interceptors.response.use((response) => {

        return response
    }, async function (error) {
        if (error.response && error.response.status === 401 && (error.response.message === 'Unauthorized' || error.response.data.message === 'Unauthorized')) {
            localStorage.removeItem('token');
            localStorage.setItem('sessionTimeOut', true);
        }

        return Promise.reject(error);
    });

}

const callAPI = {

    get: (url, data) => {

        return axiosApiInstance.get(url, { params: data })
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });


    },

    post: (url, data) => {

        return axiosApiInstance.post(url, data)
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    del: (url, data) => {

        return axiosApiInstance.delete(url, { data: data })
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    delWithParams: (url, data) => {

        return axiosApiInstance.delete(url, { params: data })
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    patch: (url, data) => {

        return axiosApiInstance.patch(url, data)
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    put: (url, data) => {

        return axiosApiInstance.put(url, data)
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },


    catchError: (response) => {

        const res = {
            message: '',
            status: response.response ? response.response.status : null
        }

        if (response.response && response.response.data.message && response.response.data.message.length > 0) {
            res.message = response.response.data.message;
        }
        else {
            res.message = 'Something went wrong.';
        }

        return res;

    }

}

export default callAPI;