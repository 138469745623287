import { Navigate, Route } from 'react-router-dom'
import { toast } from 'react-toastify';

const isLogin = () => {
    if (!localStorage.getItem('token')) {
        toast.warning("Please Login The Web Site.");
        return false
    }
    return true
}

export const PrivateRoute = ({ children }) => {
    return (
        isLogin() ?
            children
            : <Navigate to="/" />
    );
};