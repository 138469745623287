import React from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Image } from 'react-bootstrap';
import DashboardTab from '../DashboardTab/DashboardTab';
import './AdminDashboard.css'
import Logo from '../../../assets/logo.svg'
import AdminPropertyView from '../AdminPropertyView/AdminPropertyView';
import { useNavigate } from 'react-router-dom';


const AdminDashboard = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className='admin-dashboard-wrapper'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className='mx-0 admin-content-row'>
                        <Col className='side-nav-bar'>
                            <div className='logo-side-nav' onClick={() => navigate('/')}>
                                <Image src={Logo} alt='logo' />
                            </div>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Dashboard</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Property Management</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col className='side-nav-content'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <DashboardTab />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <AdminPropertyView />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

            </div>
            <div></div>
        </div>
    )
}

export default AdminDashboard