import React, { useEffect, useRef } from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useExpanded } from 'react-table'
// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChevronDown, ChevronUp, Search } from 'lucide-react';
import moment from 'moment';
import { Calendar } from 'lucide-react';
import Select from 'react-select';



// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    setGlobalFilterPlaceholder,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter((value ? value.trim() : value) || undefined)
    }, 200)

    return (
        <div className="table-search-block">
            <Search size={16} />{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${setGlobalFilterPlaceholder}`}
            />
        </div>
    )
}


function ExpandedGlobalFilter({
    globalFilter,
    setGlobalFilter,
    setGlobalFilterPlaceholder
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter((value ? value.trim() : value) || undefined)
    }, 200)

    return (
        <input
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            onClick={(event) => event.stopPropagation()}
            placeholder={`${setGlobalFilterPlaceholder}`}
        />
    )
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Filter...`}
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI for selecting
// a unique option from a note tag list
function SelectNoteTagFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const options = [
        {
            "label": "Annual Review",
            "value": "Annual Review"
        },
        {
            "label": "Arbitration",
            "value": "Arbitration"
        },
        {
            "label": "Billing",
            "value": "Billing"
        },
        {
            "label": "Collections",
            "value": "Collections"
        },
        {
            "label": "Exceptions",
            "value": "Exceptions"
        },
        {
            "label": "Line Modification",
            "value": "Line Modification"
        },
        {
            "label": "NSF",
            "value": "NSF"
        },
        {
            "label": "Other",
            "value": "Other"
        },
        {
            "label": "Plaid",
            "value": "Plaid"
        },
        {
            "label": "Remarketing",
            "value": "Remarketing"
        },
        {
            "label": "Risk",
            "value": "Risk"
        },
        {
            "label": "Shipping",
            "value": "Shipping"
        },
        {
            "label": "Subscription",
            "value": "Subscription"
        },
        {
            "label": "Underwriting",
            "value": "Underwriting"
        }
    ]


    // Render a multi-select box

    return (

        <div>
            <div className='react-table-datalist-selector mb-1 bold-400'>
                <Select options={options}
                    isClearable={true}
                    isSearchable={true}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    isMulti
                    menuPosition={'fixed'}
                    placeholder={'Note Tag'}
                    onChange={option => {
                        const data = option.map(item => item.value)
                        setFilter(data ? data : '');
                    }}
                    formatOptionLabel={(data) => {

                        return (<span className='d-flex align-items-center cursor-pointer bold-400'>
                            <span>{data.value}</span>
                        </span>)

                    }}
                    styles={
                        {
                            option: (base, state) => ({
                                ...base,
                                color: "#1e2022",
                                backgroundColor: "white",
                            }),
                        }
                    }
                />
            </div>
        </div>

    )
}

function filterNoteTag(rows, id, filterValue) {

    if (filterValue && filterValue.length > 0) {

        let noteTag = rows.filter(row => {

            if (row.original['noteTag'])

                return filterValue.includes(row.original['noteTag'])

        });

        return noteTag;
    } else {
        return rows;
    }

}

// This is a custom filter UI for selecting a date
function DateColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {

    // Render a datepicker box
    return (

        <React.Fragment>
            <div className="filtes-calenderIcon">
                <label className='mb-0'>
                    <DatePicker
                        selected={filterValue}
                        placeholderText="Filter..."
                        onChange={e => {
                            setFilter(e || undefined)
                        }}
                        dateFormat="MM-dd-y"
                        popperProps={{
                            positionFixed: true
                        }}
                        popperConfig={{ strategy: 'fixed' }}
                        popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }}
                    />
                    <Calendar size={16} />
                </label>
            </div>
        </React.Fragment>
    )

}


// custum filter for SelectSAUFilter
function SelectSAUFilter({
    column: { setFilter },
}) {
    const options = [
        {
            "label": "Active",
            "value": "Active"
        },
        {
            "label": "Inactive",
            "value": "Inactive"
        }]



    return (

        <div className='react-table-datalist-selector mb-1'>
            <Select options={options}
                isClearable={true}
                isSearchable={true}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                placeholder={'Select Status'}
                onChange={option => {
                    setFilter(option && option.value ? option.value : undefined)
                }}
                formatOptionLabel={(data) => {

                    return (<span className='d-flex align-items-center cursor-pointer bold-400'>
                        <span>{data.label}</span>
                    </span>)

                }}
                styles={
                    {
                        option: (base, state) => ({
                            ...base,
                            color: "#1e2022",
                            backgroundColor: "white",
                        }),
                    }
                }
            /></div>
    )

}

function SelectPortalAccessFilter({
    column: { setFilter },
}) {
    const options = [
        {
            "label": "Yes",
            "value": "Yes"
        },
        {
            "label": "No",
            "value": "No"
        }]

    const [portalAccess, setPortalAccess] = React.useState('');
    const [columnValue, setColumnValue] = React.useState('');

    const handleSelectChange = (selectedOption) => {
        setPortalAccess(selectedOption);
        const filterValue = selectedOption ? selectedOption.value : undefined;
        setFilter([filterValue, columnValue ? columnValue : undefined]);
    };

    const handleInputChange = (e) => {
        setColumnValue(e?.target?.value);
        setFilter([portalAccess?.value ? portalAccess.value : undefined, e?.target?.value ? e.target?.value : undefined]);
    };

    return (

        <div className='react-table-datalist-selector mb-1'>
            <Select options={options}
                isClearable={true}
                isSearchable={true}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                placeholder={'Portal Access'}
                onChange={option => handleSelectChange(option)}
                formatOptionLabel={(data) => {

                    return (<span className='d-flex align-items-center cursor-pointer bold-400'>
                        <span>{data.label}</span>
                    </span>)

                }}
                styles={
                    {
                        option: (base, state) => ({
                            ...base,
                            color: "#1e2022",
                            backgroundColor: "white",
                        }),
                    }
                }
            />
            <div className='risk-level-search-dealer mt-1'>
                <input
                    onChange={e => handleInputChange(e)}
                    placeholder={`Filter...`}
                />
            </div>
        </div>
    )

}

// This is a custom filter UI for selecting from Dropdown
function ReactSelectFilter({
    column: { filterValue, setFilter, preFilteredRows, id, dealerOptions },
}) {

    return (
        <Select
            options={dealerOptions}
            isClearable={true}
            isSearchable={true}
            onChange={option => setFilter(option ? option.value || undefined : '')}
            name="dealersListReactTable"
            placeholder="Filter..."
        />
    )

}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ dataLoaded, columns, data, noOfColumns, renderRowSubComponent, expanded, showFilters, globalFilter, autoResetFilters, autoResetGlobalFilter, showGlobalFilter, showExpandedGlobalFilter, globalFilterPlaceholderText, isReset, setReset, hideFilters, rowProps = () => ({}), filteredDataSync, globalFilterValue, globalFilterAvailable, isCustomSorting, tableCustomSorting, sortingExclude }) {
    const filterOptions = React.useRef({ matchedIds: [], preRows: null });
    const [clicksCount, setClicksCount] = React.useState(0);
    const [headerName, setHeaderName] = React.useState('');
    const initialState = React.useRef({
        globalFilter
    });
    // const resetButton = useRef();
    // useEffect(() => {
    //     if (isReset) {
    //         resetButton.current.click();
    //         hideFilters();
    //         setReset();
    //     }

    // }, [isReset])

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        setAllFilters

    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            autoResetFilters,
            autoResetGlobalFilter: false,
            autoResetSortBy: false,
            expanded,
            initialState: initialState.current,
            getSubRows: (row) => row.subRows,
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useExpanded
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows;

    useEffect(() => {

        if (filteredDataSync) {
            filteredDataSync(rows.map(item => {
                return item.original;
            }));
        }

    }, [rows])

    useEffect(() => {

        if (autoResetFilters) {
            setAllFilters([]);
        }

    }, [autoResetFilters])

    useEffect(() => {
        if (showFilters === false) {
            setGlobalFilter('')
        }
    }, [showFilters])

    useEffect(() => {
        filterOptions.current.matchedIds = [];
        if (globalFilterAvailable)
            setGlobalFilter(globalFilterValue);
    }, [globalFilterValue, setGlobalFilter])

    var innertableJSX = '';

    const customSorting = (column) => {
        let sortingType = ''
        let isInitial = (clicksCount === 0) ? true : false

        if (column.Header) {
            setHeaderName(column.Header)
        }

        if (clicksCount < 3 && (column.Header === headerName || isInitial)) {
            if ([0, 1].includes(clicksCount) && isInitial) {
                sortingType = -1
            } else if (clicksCount === 1) {
                sortingType = 1
            }
            setClicksCount(clicksCount + 1)
        } else {
            sortingType = -1
            setClicksCount(1)
        }

        if (!sortingExclude.includes(column.Header)) {
            tableCustomSorting(column.sortKey, sortingType)
        }
    }

    return (
        <>
            {showGlobalFilter ? (<GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                setGlobalFilterPlaceholder={globalFilterPlaceholderText}
            />) : null
            }
            {/* <div className="table_scroller" style={rows.length > 5 || (rows && rows[0] && rows[0].isExpanded && rows.length < 3 && rows[0] && rows[0].subRows && rows[0].subRows.length > 3) ? { overflowY: 'auto' } : { overflowY: 'visible', maxHeight: 'unset' }}> */}
            <div className={"table_scroller" + (rows.length <= 4 ? ' table-overflow-visible ' : '') + (rows.length === 1 ? ' mainTableOpen ' : ' mainTableClose')}>

                <table className="table table-responsive table-hover default-table" {...getTableProps()}>
                    <thead className="table-header-bg-color">
                        {headerGroups.map(headerGroup => (
                            <React.Fragment>
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...(isCustomSorting ? { onClick: () => customSorting(column) } : { ...column.getHeaderProps([{ className: column.className }, column.getSortByToggleProps()]) })} >
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ChevronUp
                                                            size={19}
                                                            className="column-sort-icon expanded-row-icon"
                                                        />
                                                    ) : (
                                                        <ChevronDown
                                                            size={19}
                                                            className="column-sort-icon expanded-row-icon"
                                                        />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                            <span>

                                                {(clicksCount !== 0 && !sortingExclude.includes(column.Header) && column?.Header === headerName) ?
                                                    ((clicksCount === 1) ?
                                                        <ChevronDown
                                                            size={19}
                                                            className="column-sort-icon expanded-row-icon"
                                                        /> :
                                                        (clicksCount === 2) ?
                                                            <ChevronUp
                                                                size={19}
                                                                className="column-sort-icon expanded-row-icon"
                                                            /> : null) : null
                                                }
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                                {showFilters ?
                                    <tr className="table-filters" {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {/* Render the columns filter UI */}
                                                {showExpandedGlobalFilter && column.canFilter && !column.restrictInput ? (<ExpandedGlobalFilter
                                                    globalFilter={globalFilter}
                                                    setGlobalFilter={setGlobalFilter}
                                                    setGlobalFilterPlaceholder={globalFilterPlaceholderText}
                                                    showFilters={showFilters}
                                                ></ExpandedGlobalFilter>) : <div
                                                    onClick={(event) => event.stopPropagation()}>
                                                    {column.canFilter ? column.render('Filter') : null}
                                                </div>}
                                            </th>
                                        ))}
                                    </tr> : null}
                            </React.Fragment>
                        ))}
                        {/* <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                        </th>
                    </tr> */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {firstPageRows && firstPageRows.length > 0 ?
                            firstPageRows.map((row, i) => {
                                prepareRow(row);
                                innertableJSX = row.original.isOpen ?
                                    row.original.innerJSX
                                    : null
                                return (
                                    <React.Fragment key={rowProps.key}>
                                        {row.isExpanded ?
                                            <tr className="Expanded" {...row.getRowProps(rowProps(row))}>
                                                {row.cells.map(cell => {
                                                    return <td  {...cell.getCellProps({
                                                        className: cell.column.className
                                                    })}>{cell.render('Cell')}</td>
                                                })}
                                            </tr> :
                                            row.original.isOpen ? <React.Fragment><tr className={'normal'} {...row.getRowProps(rowProps(row))}>
                                                {row.cells.map(cell => {
                                                    return <td  {...cell.getCellProps({
                                                        className: cell.column.className
                                                    })}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                                {/* {innertableJSX = <div className='innertable-tow innertable-row'>{row.original.innerJSX}</div>} */}
                                            </React.Fragment> :
                                                <tr className={row.depth ? 'subrows' : 'normal'} {...row.getRowProps(rowProps(row))}>
                                                    {row.cells.map(cell => {
                                                        return <td  {...cell.getCellProps({
                                                            className: cell.column.className
                                                        })}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>}
                                    </React.Fragment>
                                )
                            })
                            : <tr>
                                <td colSpan={columns.length} className='no-data-available'>
                                    {dataLoaded ? 'Data is Processing' : 'No Data Available'}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className='overflow-hidden'>
                    <div className={`expandview-table ${innertableJSX ? 'isopen' : 'isclose'}`}>
                        {innertableJSX}
                    </div>
                </div>
            </div>
        </>
    )
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// Define a custom filter filter function!
function filterDate(rows, id, filterValue) {

    return rows.filter(row => {
        const rowValue = row.values[id]
        return moment.utc(rowValue).format('MM/DD/YYYY') == moment(filterValue).format('MM/DD/YYYY')
    })

}

//custom filter for portal access 
function supplierFilter(rows, id, filterValue) {
    if (filterValue && filterValue.length > 1) {
        const filteredArray = filterValue.filter(value => value !== undefined);

        return rows?.filter(row => {
            const rowValue = row?.values[id]?.toLowerCase()
            return filteredArray?.every(keyword => rowValue?.includes(keyword?.toLowerCase()));
        })
    }
    else {
        return rows;
    }

}
// Define a custom Range filter function!
function filterDateRange(rows, id, filterValue) {

    if (filterValue && filterValue.length > 1 && filterValue[0]) {
        return rows.filter(row => {
            const rowValue = row.values[id]
            const startDate = moment(filterValue[0]).format('MM/DD/YYYY');
            const endDate = moment(filterValue[1] ? filterValue[1] : filterValue[0]).format('MM/DD/YYYY');
            const rowDate = moment.utc(rowValue).format('MM/DD/YYYY');
            return moment(rowDate).isBetween(startDate, endDate, undefined, '[]');
        })
    }
    else {
        return rows;
    }

}




function rowValuesToReturn(filterValueLowerCase, row) {
    if (row.values['Dealer Details']) return row.values['Dealer Details'].toLowerCase().includes(filterValueLowerCase);
    else if (row.values['Dealer Name']) return row.values['Dealer Name'].toLowerCase().includes(filterValueLowerCase);
    else if (row.values['Dealer']) return row.values['Dealer'].toLowerCase().includes(filterValueLowerCase);
    else if (row.values['Vehicle']) return row.values['Vehicle'].toLowerCase().includes(filterValueLowerCase);
    else if (row.values['User Details']) return row.values['User Details'].toLowerCase().includes(filterValueLowerCase)
    return row;
}

function filterColorRange(rows, id, filterValue) {
    if (filterValue) {
        const filterValueLowerCase = filterValue[1] ? filterValue[1].toLowerCase() : '';
        if ((filterValue[0] === 'All') || (filterValue[0] === '')) {
            let allRows = rows.filter(row => {
                return rowValuesToReturn(filterValueLowerCase, row);
            })
            return allRows;
        }
        else {
            let risk = rows.filter(row => {
                let status = {
                    original: row.original['riskLevelStatus'],
                    dealerId: row.original.dealerId && row.original.dealerId['riskLevelStatus'],
                    dealerDetails: row.original.dealerDetails && row.original?.dealerDetails['riskLevelStatus']
                }

                if ((status.original === '' || status.dealerId === '' || status.dealerDetails === '') && filterValue[0] === 'Low') {
                    return status.original === '' || status.dealerId === '' || status.dealerDetails === '';
                }

                if (row.original['riskLevelStatus']) return row.original['riskLevelStatus'] === filterValue[0]
                else if (row.original.dealerId && row.original.dealerId['riskLevelStatus']) return row.original.dealerId['riskLevelStatus'] === filterValue[0];
                else if (row.original.dealerDetails && row.original.dealerDetails['riskLevelStatus']) return row.original.dealerDetails['riskLevelStatus'] === filterValue[0];

                return status.original || status.dealerId || status.dealerDetails;

            });
            if (filterValue[1] && filterValue[1].length) {

                return risk.filter(row => {
                    return rowValuesToReturn(filterValueLowerCase, row);
                });

            }
            return risk;
        }

    }
    else {
        return rows;
    }

}


// Define a custom Dealers search filter function!
function filterDealerSelect(rows, id, filterValue) {

    if (filterValue) {

        if (filterValue === 'all')
            return rows;
        else {
            const filterValues = filterValue.split(' - ');
            return rows.filter(row => row.values['dealership'] === filterValues[1]);
        }

    }
    else {
        return rows;
    }

}

// Define a custom filter filter function!
function filterReactJSX(rows, id, filterValue) {

    return rows.filter(row => {

        const rowValue = row.values[id];
        const children = rowValue !== '' ? (rowValue.props ? rowValue.props.children : rowValue) : '';

        if (children !== '') {

            for (let i = 0; i < children.length; i++) {

                if (children[i].props && children[i].props.children && children[i].props.children.toString().toLowerCase().indexOf(filterValue.toString().toLowerCase()) > -1)
                    return true;
                else if (children.toString().toLowerCase().indexOf(filterValue.toString().toLowerCase()) > -1)
                    return true;

            }

        }

        return false;

    })
}


//Custom filter code
function filterCustomReactJSX(rows, id, filterValue) {

    return rows.filter(row => {

        const rowValue = row.values[id];
        const children = rowValue !== '' ? (rowValue.props && rowValue.props.children && rowValue.props.children.length > 0 ? rowValue.props.children[0].props.children.toLowerCase() : rowValue.toLowerCase()) : '';

        if (children.indexOf(filterValue.toLowerCase()) > -1) {
            return true
        } else {
            return false
        }

    })
}


// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

export default Table;
export { filterDate, DateColumnFilter, filterReactJSX, SelectSAUFilter, SelectPortalAccessFilter, SelectNoteTagFilter, filterNoteTag, filterDateRange, filterColorRange, ReactSelectFilter, filterDealerSelect, filterCustomReactJSX, supplierFilter }