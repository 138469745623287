import React from "react";
import Slider from "react-slick";
import SliderImage from '../../../../assets/Properties/img1.svg'
import { Card, Image } from "react-bootstrap";
import BedIcon from '../../../../assets/Properties/bed.svg'
import './CustomSlickSlider.css'
import { ChevronLeft, ChevronRight } from "lucide-react";
import PopularTag from '../../../../assets/Slider/Popularr.svg'
import DiscountTag from '../../../../assets/Slider/Discountt.svg'
import NewListingTag from '../../../../assets/Slider/NewListingg.svg'

function CustomSlickSlider({ cardData, lucideIcons, propertyTag }) {
    var settings = {
        arrows: true, // Show arrows navigation
        dots: false, // Hide dots navigation
        infinite: true,
        speed: 100, // Faster transition speed
        autoplay: false,
        autoplaySpeed: 1000,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        initialSlide: 0,
        pauseOnHover: true,
        nextArrow: lucideIcons ? <ChevronRight className="color-000" /> : undefined, // Conditional rendering
        prevArrow: lucideIcons ? <ChevronLeft className="color-000" /> : undefined,  // Conditional rendering
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    autoplay: false,
                }
            }
        ]
    };

    return (
        <div className="custom-slick-carousel">
            <div className="slider-container">
                <Slider {...settings}>
                    {cardData.map((slide, index) => (
                        <Card key={index}>
                            <div className='position-relative slider-images'>
                                <Image src={slide.propertyImage || SliderImage} alt='property image' />
                                {propertyTag && <span>
                                    {propertyTag === 'Popular' ? <span className='popular-tag'><Image src={PopularTag} /></span> : propertyTag === 'Discount' ? <span className='discount-tag'><Image src={DiscountTag} /></span> : <span className='new-listing-tag'><Image src={NewListingTag} /></span>}
                                </span>}
                            </div>
                            <div>
                                <h4>{slide?.price ? `₹ ${slide.price}` : (slide?.monthlyRentForRoom ? `₹ ${slide.monthlyRentForRoom}` : '')}</h4>
                                <h5>{slide?.propertyLocation ?? slide.fullAddress}</h5>
                                <p className="line-clamp ">{slide.propertyAddress}</p>
                                <div className='counts d-flex align-items-center'>
                                    <span className='me-3 d-flex beds align-items-center'>
                                        <Image className='me-2' src={BedIcon} alt='beds' />
                                        Double | Triple | Private
                                    </span>
                                </div>
                            </div>
                        </Card>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default CustomSlickSlider;
