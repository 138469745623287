import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import '../src/components/Shared/common/css/common.css'
import '../src/components/Shared/common/css/colors.css'
import Home from './components/Home/Home';
import SearchResults from './components/SearchResults/SearchResults';
import PropertyPage from './components/PropertyPage/PropertyPage';
import OrderSummary from './components/OrderSummary/OrderSummary';
import ConfirmBooking from './components/ConfirmBooking/ConfirmBooking';
import Collections from './components/Collections/Collections';
import CollectionPage from './components/Collections/Variations/CollectionPage';
import Footer from './components/Footer/Footer';
import AboutUs from './components/AboutUs/AboutUs';
import { PrivateRoute } from './commonservices/ProtectRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminPropertyView from './components/AdminPortal/AdminPropertyView/AdminPropertyView';
import NewForm from './components/NewForm/NewForm';
import AdminDashboard from './components/AdminPortal/AdminDashboard/AdminDashboard';
import Blogs from './components/Blogs/Blogs';
import BlogDetails from './components/Blogs/BlogDetails/BlogDetails';
import NewAdminPortal from './components/NewAdminPortal/NewAdminPortal';
import Dashboard from './components/AdminPortal/Dashboard/Dashboard';
import Profile from './components/Profile/Profile';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Content from './components/Content/Content';
import ViewProperty from './components/AdminPortal/ViewProperty/ViewProperty';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/searchresults' element={<SearchResults />} />
        <Route path='/changePassword' element={<ChangePassword />} />
        <Route path='/property/:id' element={<PropertyPage />} />
        <Route path='/ordersummary' element={<PrivateRoute><OrderSummary /></PrivateRoute>} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/confirmbooking' element={<PrivateRoute><ConfirmBooking /></PrivateRoute>} />
        <Route path='/collections' element={<Collections />} />
        <Route path='/eachcollections' element={<CollectionPage />} />
        <Route path='/adminPropertyView' element={<AdminPropertyView />} />
        <Route path='/adminPropertyView/:id' element={<ViewProperty />} />
        <Route path='/newForm' element={<NewForm />} />
        <Route path='/adminDashboard' element={<AdminDashboard />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blogsDetails' element={<BlogDetails />} />
        <Route path='/blogsDetails/:id' element={<BlogDetails />} />
        <Route path='/dashBoard' element={<Dashboard />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/newAdminPortal' element={<NewAdminPortal />} />
        <Route path='/content' element={<Content />} />
      </Routes>
      <ToastContainer position="top-right" />
      <Footer />
    </div>
  );
}

export default App;
