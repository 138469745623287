import React from 'react'
import NewAdminHeader from '../../../NewAdminPortal/NewAdminHeader/NewAdminHeader'
import SideNavBar from '../../../NewAdminPortal/SideNavBar/SideNavBar'
import './AdminLayout.css'

const AdminLayout = ({ children }) => {
    return (
        <div className='d-flex'>
            <SideNavBar />
            <div className='flex-grow-1 right-panel-admin'>
                <div className='admin-all-products-wrapper admin-content-wrapper'>
                    <NewAdminHeader />
                    <div className='admin-tab-content-box'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLayout